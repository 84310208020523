import { EquipmentProvider } from "../../../basicdata/equipment/EquipmentContext";
import { ManufacturerProvider } from "../../../basicdata/manufacturer/ManufacturerContext";
import { PlaygroundReportEquipmentsByManufacturerProvider } from "../equipmentsByManufacturer/PlaygroundReportEquipmentsByManufacturerContext";
import EntertainmentReportEquipment from "./EntertainmentReportEquipmentPage";

const PlaygroundReportEquipmentsPage = () => {
    return (
        <EquipmentProvider>
            <ManufacturerProvider>
                <PlaygroundReportEquipmentsByManufacturerProvider>
                    <EntertainmentReportEquipment />
                </PlaygroundReportEquipmentsByManufacturerProvider>
            </ManufacturerProvider>
        </EquipmentProvider>
    );
};

export default PlaygroundReportEquipmentsPage;
