import {
    DataGrid,
    GridActionsCellItem,
    GridToolbarQuickFilter,
    useGridApiContext,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import usePlaygroundReportEquipmentsContext from "./usePlaygroundReportEquipmentsContext";
import { Stack } from "@mui/system";
import PrevNextButtons from "../PrevNextButtons";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import ReportListDialog from "./copyequipmentsfromreport/ReportListDialog";
import useReportContext from "../useReportContext";
import { useReportApi } from "../../../api/reportApi";
import { ReportType } from "../../../utils/reportType";
import { useQuickFilterWithFiltering } from "../../common/DiacriticSearch";

function QuickSearchToolbar({ quickFilterProps }) {
    return (
        <Box sx={{ p: 1 }}>
            <GridToolbarQuickFilter {...quickFilterProps} />
        </Box>
    );
}

function PlaygroundReportEquipmentsList() {
    const {
        reportEquipments,
        setEquipmentSelection,
        inspectionTypes,
        changeInspectionType,
        deleteEquipment,
        saveReportEquipments,
        overrideManufacturer,
        setOverrideManufacturer,
        fetchReportEquipments,
        priorityManufacturers,
        selectedPriorityManufacturer,
        setSelectedPriorityManufacturer,
    } = usePlaygroundReportEquipmentsContext();
    const [showCopyReportDialog, setShowCopyReportDialog] = useState(false);
    const { report } = useReportContext();
    const { copyReportEquipmentsFromOtherReport } = useReportApi();

    function SelectInspectionTypeCell(props) {
        const { id, field } = props;
        const apiRef = useGridApiContext();

        const handleChange = async (props, event) => {
            const inspectionType = event.target.value;
            changeInspectionType(props.row, inspectionType);
            await apiRef.current.setEditCellValue({
                id,
                field,
                value: inspectionType,
            });
            apiRef.current.stopCellEditMode({ id, field });
        };

        return (
            <FormControl fullWidth>
                <Select
                    value={props.row.inspectionType}
                    onChange={(e) => handleChange(props, e)}
                    renderValue={(item) => item.name}
                >
                    {inspectionTypes.map((inspectionType) => (
                        <MenuItem
                            key={inspectionType.id}
                            value={inspectionType}
                        >
                            {inspectionType.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }

    const renderInspectionTypeCell = (params) => {
        return <SelectInspectionTypeCell {...params} />;
    };

    const handleNewEquipmentsClick = () => {
        setEquipmentSelection(true);
    };

    function copyEquipmentsFromReport(copyFromReportId) {
        copyReportEquipmentsFromOtherReport(
            report.baseData.id,
            copyFromReportId
        ).then((response) => {
            fetchReportEquipments();
            setShowCopyReportDialog(false);
        });
    }

    var columns = [];
    columns.push(
        {
            field: "manufacturer",
            headerName: "Gyártó",
            minWidth: 70,
            flex: 0.1,
            valueGetter: (params) => params.row.equipment.manufacturer.name,
        },
        {
            field: "name",
            headerName: "Név",
            minWidth: 70,
            flex: 0.15,
            valueGetter: (params) => params.row.equipment.name,
        },
        {
            field: "typeNumber",
            headerName: "Cikkszám",
            minWidth: 130,
            flex: 0.1,
            valueGetter: (params) => params.row.equipment.typeNumber,
        },
        {
            field: "certificateNumber",
            headerName: "Tanúsítvány szám",
            minWidth: 130,
            flex: 0.15,
            valueGetter: (params) => params.row.equipment.certificateNumber,
        }
    );
    if (report.baseData.reportType.code === ReportType.SPORT_EQUIPMENT.code) {
        columns.push({
            field: "declarationNumber",
            headerName: "Nyilatkozat szám",
            minWidth: 130,
            flex: 0.15,
            valueGetter: (params) =>
                params.row.equipment.manufacturerDeclarationNumber,
        });
    }
    columns.push(
        {
            field: "inspectionType",
            headerName: "Ellenőrzés jellege",
            minWidth: 50,
            flex: 0.15,
            renderCell: renderInspectionTypeCell,
        },
        {
            field: "actions",
            type: "actions",
            headerName: "",
            width: 50,
            cellClassName: "actions",
            getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Törlés">
                                <DeleteIcon />
                            </Tooltip>
                        }
                        label="Delete"
                        onClick={() => {
                            deleteEquipment(params.row.reportEquipmentId);
                        }}
                        color="inherit"
                    />,
                ];
            },
        }
    );

    // Function to get a field's value for filtering
    const getFieldValue = (row, field) => {
        if (field === "manufacturer") return row.equipment.manufacturer.name;
        if (field === "name") return row.equipment.name;
        if (field === "typeNumber") return row.equipment.typeNumber;
        if (field === "certificateNumber")
            return row.equipment.certificateNumber;
        if (field === "declarationNumber")
            return row.equipment.manufacturerDeclarationNumber;
        return row[field]; // Default direct field access
    };

    // Use the custom quick filter hook for multiple fields
    const { filteredRows, quickFilterProps } = useQuickFilterWithFiltering(
        reportEquipments,
        [
            "manufacturer",
            "name",
            "typeNumber",
            "certificateNumber",
            "declarationNumber",
        ],
        getFieldValue
    );

    return (
        <div style={{ height: 500, width: "100%" }}>
            <Stack direction="row" spacing={2} mb={2} alignItems="center">
                <Box>
                    <Button
                        variant="contained"
                        name="new"
                        onClick={handleNewEquipmentsClick}
                    >
                        Eszközök hozzáadása
                    </Button>
                </Box>
                <FormControlLabel
                    label="Kiemelt partner"
                    control={
                        <Checkbox
                            name="overrideManufacturer"
                            checked={overrideManufacturer}
                            onChange={(e) =>
                                setOverrideManufacturer(e.target.checked)
                            }
                        />
                    }
                />
                <Autocomplete
                    sx={{ width: "53%", my: 1 }}
                    id="free-solo-demo"
                    name="priorityManufacturers"
                    key={selectedPriorityManufacturer}
                    value={selectedPriorityManufacturer}
                    disabled={!overrideManufacturer}
                    onChange={(event, newValue) => {
                        setSelectedPriorityManufacturer(newValue);
                    }}
                    options={priorityManufacturers}
                    getOptionLabel={(option) => option?.name}
                    renderInput={(params) => (
                        <TextField {...params} label="Partner" />
                    )}
                />
                <Button>
                    <Tooltip title="Eszközök másolása korábbi jegyzőkönyvből">
                        <ManageSearchIcon
                            onClick={() => {
                                setShowCopyReportDialog(true);
                            }}
                        />
                    </Tooltip>
                </Button>
            </Stack>
            <DataGrid
                rows={filteredRows}
                getRowId={(row) => row.reportEquipmentId}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableColumnSelector
                components={{ Toolbar: QuickSearchToolbar }}
                componentsProps={{
                    toolbar: {
                        quickFilterProps: quickFilterProps,
                    },
                }}
                experimentalFeatures={{ newEditingApi: true }}
            />
            <Dialog
                fullWidth
                maxWidth="lg"
                open={showCopyReportDialog}
                onClose={() => {
                    setShowCopyReportDialog(false);
                }}
            >
                <DialogTitle>
                    Eszközök másolása korábbi jegyzőkönyvből
                </DialogTitle>
                <DialogContent>
                    <ReportListDialog
                        reportType={report.baseData.reportType}
                        onClose={() => {
                            setShowCopyReportDialog(false);
                        }}
                        onReportSelected={(reportId) => {
                            setShowCopyReportDialog(false);
                            copyEquipmentsFromReport(reportId);
                        }}
                    />
                </DialogContent>
            </Dialog>
            <PrevNextButtons
                nextFunction={() =>
                    saveReportEquipments().then((success) => success)
                }
            />
        </div>
    );
}

export default PlaygroundReportEquipmentsList;
