import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useLocationContext from "./useLocationContext";
import React from "react";
import { useQuickFilterWithFiltering } from "../../common/DiacriticSearch";

const columns = [
    { field: "name", headerName: "Név", minWidth: 70, flex: 0.2 },
    { field: "city", headerName: "Város", minWidth: 130, flex: 0.2 },
    { field: "zip", headerName: "Ir.szám", minWidth: 130, flex: 0.2 },
    { field: "address", headerName: "Cím", minWidth: 130, flex: 0.2 },
    {
        field: "operator",
        headerName: "Üzemeltető",
        minWidth: 130,
        flex: 0.2,
        valueGetter: (params) => params.row.operator.name,
    },
    {
        field: "contactPerson",
        headerName: "Kapcsolattartó",
        minWidth: 130,
        flex: 0.2,
    },
];

// Function to get a field's value for filtering
const getFieldValue = (row, field) => {
    if (field === "operator") return row.operator.name; // Handle valueGetter for operator
    return row[field]; // Default direct field access
};

function LocationTable() {
    const { locations, setSelectedLocation } = useLocationContext();

    function selectionModelChanged(ids) {
        const id = ids.length !== 0 ? ids[0] : null;
        setSelectedLocation(id);
    }

    // Use the custom quick filter hook for multiple fields
    const { filteredRows, quickFilterProps } = useQuickFilterWithFiltering(
        locations,
        ["name", "city", "zip", "address", "operator", "contactPerson"],
        getFieldValue // Pass the custom field value getter
    );

    return (
        <div style={{ height: 500, width: "100%" }}>
            <DataGrid
                rows={filteredRows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps,
                    },
                }}
                onSelectionModelChange={(ids) => selectionModelChanged(ids)}
            />
        </div>
    );
}

export default LocationTable;
