import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import { ROLE } from "../../utils/Roles";
import useAuth from "../../hooks/useAuth";

const EntertainmentReportCard = () => {
    const { auth } = useAuth();
    return (
        <Box>
            <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{
                    backgroundColor: "primary.main", // using the theme's primary color
                    color: "primary.contrastText", // ensuring text is readable on the primary color
                    padding: "8px", // adding some padding
                    borderRadius: "4px", // optional rounded corners
                }}
            >
                Szórakoztató eszköz
            </Typography>
            <List>
                <ListItem disablePadding>
                    <ListItemButton component="a" href="/report/entertainment">
                        <ListItemIcon>
                            <AddIcon />
                        </ListItemIcon>
                        <ListItemText primary="Új" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton
                        component="a"
                        href="/report/entertainment/list"
                    >
                        <ListItemIcon>
                            <EditIcon />
                        </ListItemIcon>
                        <ListItemText primary="Módosítás" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );
};

export default EntertainmentReportCard;
