import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useEquipmentContext from "./useEquipmentContext";
import { Autocomplete, Checkbox, FormControlLabel } from "@mui/material";
import { Stack } from "@mui/system";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useEquipmentApi } from "../../../api/equipmentApi";

function EquipmentEditDialog({ manufacturer, equipmentCategory, onClose }) {
    const { saveEquipment } = useEquipmentApi();
    const {
        data,
        equipmentTypes,
        equipmentCategories,
        manufacturers,
        certificateIssuers,
        handleChange,
        handleCertificateIssueDate,
        showNewEquipmentDialog,
        setShowNewEquipmentDialog,
        errors,
        hasError,
    } = useEquipmentContext();

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;

        switch (event.target.name) {
            case "save": {
                saveEquipment(data, (response) => {
                    onClose(response);
                });
                break;
            }
            default:
                break;
        }
        setShowNewEquipmentDialog(false);
    };

    if (manufacturer && data.manufacturer !== manufacturer) {
        const event = {};
        event.target = {};
        event.target.type = "autocomplete";
        event.target.name = "manufacturer";
        event.target.selectedOption = manufacturer;
        handleChange(event);
    }

    if (equipmentCategory && data.category !== equipmentCategory) {
        const event = {};
        event.target = {};
        event.target.type = "autocomplete";
        event.target.name = "category";
        event.target.selectedOption = equipmentCategory;
        handleChange(event);
    }

    const title = data.id == null ? "Új eszköz" : "Eszköz szerkesztés";

    return (
        <Dialog
            disableEscapeKeyDown
            fullWidth
            maxWidth="md"
            open={showNewEquipmentDialog}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic name"
                    label="Név"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={data.name}
                    error={errors.name != null}
                    helperText={errors.name}
                    name="name"
                    required
                />
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic name"
                    label="Cikkszám"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={data.typeNumber}
                    error={errors.typeNumber != null}
                    helperText={errors.typeNumber}
                    name="typeNumber"
                />
                <Stack direction="row" spacing={2} sx={{ my: 1 }}>
                    <Autocomplete
                        sx={{ width: "50%" }}
                        id="free-solo-demo"
                        name="category"
                        value={data.category}
                        disabled={equipmentCategory != null}
                        onChange={(event, newInputValue) => {
                            if (event != null) {
                                event.target.type = "autocomplete";
                                event.target.name = "category";
                                event.target.selectedOption = newInputValue;
                                handleChange(event);
                            }
                        }}
                        isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                        }
                        options={equipmentCategories}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField {...params} label="Eszköz kategória *" />
                        )}
                    />
                    <Autocomplete
                        sx={{ width: "50%" }}
                        id="free-solo-demo"
                        name="equipmentType"
                        value={data.type}
                        disabled={equipmentTypes.length === 0}
                        onChange={(event, newInputValue) => {
                            if (event != null) {
                                event.target.type = "autocomplete";
                                event.target.name = "type";
                                event.target.selectedOption = newInputValue;
                                handleChange(event);
                            }
                        }}
                        isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                        }
                        options={equipmentTypes}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField {...params} label="Eszköz típus" />
                        )}
                    />
                </Stack>
                <Stack direction="row" spacing={2} sx={{ my: 2 }}>
                    <TextField
                        sx={{ width: "75%" }}
                        id="outlined-basic name"
                        label="Tanúsítvány szám"
                        variant="outlined"
                        type="text"
                        autoComplete="off"
                        onChange={handleChange}
                        value={data.certificateNumber}
                        error={errors.certificateNumber != null}
                        helperText={errors.certificateNumber}
                        name="certificateNumber"
                        required={data?.category?.id === 1}
                    />
                    <DesktopDatePicker
                        sx={{ width: "25%" }}
                        label="Kibocsátás"
                        inputFormat="YYYY.MM.DD"
                        value={data.certificateDate}
                        onChange={handleCertificateIssueDate}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </Stack>
                <Autocomplete
                    sx={{ width: "100%", my: 2 }}
                    id="free-solo-demo"
                    name="certificateIssuer"
                    value={data.certificateIssuer}
                    onChange={(event, newInputValue) => {
                        if (event != null) {
                            event.target.type = "autocomplete";
                            event.target.name = "certificateIssuer";
                            event.target.selectedOption = newInputValue;
                            handleChange(event);
                        }
                    }}
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    options={certificateIssuers}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField {...params} label="Tanúsítvány kibocsátó" />
                    )}
                />
                <Autocomplete
                    sx={{ width: "100%", my: 2 }}
                    id="free-solo-demo"
                    name="manufacturer"
                    value={data.manufacturer}
                    disabled={manufacturer != null}
                    onChange={(event, newInputValue) => {
                        if (event != null) {
                            event.target.type = "autocomplete";
                            event.target.name = "manufacturer";
                            event.target.selectedOption = newInputValue;
                            handleChange(event);
                        }
                    }}
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    options={manufacturers}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField {...params} label="Gyártó" />
                    )}
                />
                {data?.category?.id === 2 && (
                    <TextField
                        sx={{ width: "100%" }}
                        id="outlined-basic name"
                        label="Gyártói nyilatkozat száma"
                        variant="outlined"
                        type="text"
                        autoComplete="off"
                        onChange={handleChange}
                        value={data.manufacturerDeclarationNumber}
                        error={errors.manufacturerDeclarationNumber != null}
                        helperText={errors.manufacturerDeclarationNumber}
                        name="manufacturerDeclarationNumber"
                        required={data?.category?.id === 2}
                    />
                )}
                <FormControlLabel
                    control={
                        <Checkbox
                            defaultChecked
                            name="active"
                            onChange={handleChange}
                            checked={data.active}
                        />
                    }
                    label="Aktív"
                />
            </DialogContent>
            <DialogActions>
                <Button name="cancel" onClick={handleClose}>
                    Mégse
                </Button>
                <Button name="save" disabled={hasError} onClick={handleClose}>
                    Mentés
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EquipmentEditDialog;
