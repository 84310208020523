import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useEquipmentCategoryContext from "./useEquipmentCategoryContext";
import { useQuickFilterWithFiltering } from "../../common/DiacriticSearch";

const columns = [
    { field: "name", headerName: "Név", minWidth: 70, flex: 0.4 },
    {
        field: "description",
        headerName: "Megjegyzés",
        minWidth: 130,
        flex: 0.6,
    },
];

function EquipmentCategoryTable() {
    const { equipmentCategories, setSelectedEquipmentCategory } =
        useEquipmentCategoryContext();

    function selectionModelChanged(ids) {
        const id = ids.length !== 0 ? ids[0] : null;
        setSelectedEquipmentCategory(id);
    }

    // Use the custom quick filter hook for multiple fields
    const { filteredRows, quickFilterProps } = useQuickFilterWithFiltering(
        equipmentCategories,
        ["name", "description"]
    );

    return (
        <div style={{ height: 500, width: "100%" }}>
            <DataGrid
                rows={filteredRows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps,
                    },
                }}
                onSelectionModelChange={(ids) => selectionModelChanged(ids)}
            />
        </div>
    );
}

export default EquipmentCategoryTable;
