import useFullScreenProgressBarContext from "../components/common/fullscreenprogressbar/useFullScreenProgressBarContext";
import useGeneralErrorContext from "../components/common/generalerrorsnackbar/useGeneralErrorContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { LOCATION_URL } from "./Url";

export function useLocationApi() {
    const { setShowFullScreenProgressBar } = useFullScreenProgressBarContext();
    const { handleGeneralError } = useGeneralErrorContext();
    const axiosPrivate = useAxiosPrivate();

    async function getLocations(onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .get(`${LOCATION_URL}/list`, {
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function saveLocation(data, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            const request = {
                id: data.id,
                name: data.name,
                city: data.city,
                zip: data.zip,
                address: data.address,
                operator: data.operator.id,
                contactPerson: data.contactPerson,
                contactEmail: data.contactEmail,
            };
            if (request.id == null) {
                await axiosPrivate
                    .post(`${LOCATION_URL}`, JSON.stringify(request), {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    })
                    .then((response) => {
                        if (typeof onSuccess === "function")
                            onSuccess(response);
                    });
            } else {
                await axiosPrivate
                    .put(
                        `${LOCATION_URL}/${request.id}`,
                        JSON.stringify(request),
                        {
                            headers: { "Content-Type": "application/json" },
                            withCredentials: true,
                        }
                    )
                    .then((response) => {
                        if (typeof onSuccess === "function")
                            onSuccess(response);
                    });
            }
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function uploadLocationCsv(data, onSuccess) {
        setShowFullScreenProgressBar(true);
        const formData = new FormData();
        formData.append("File", data);

        try {
            await axiosPrivate
                .post(`${LOCATION_URL}/list`, formData, {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function deleteLocation(id, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .delete(`${LOCATION_URL}/${id}`, {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    return {
        getLocations,
        saveLocation,
        uploadLocationCsv,
        deleteLocation,
    };
}
