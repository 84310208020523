import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import PrevNextButtons from "../../PrevNextButtons";
import ImageGalleryWithDelete from "../../../common/CustomImageList";
import useReportImageContext from "../../useReportImagesContext";
import { useImageApi } from "../../../../api/imagesApi";
import useReportContext from "../../useReportContext";
import { useEffect, useState } from "react";
import { useReportApi } from "../../../../api/reportApi";
import CustomDropzone from "../../../common/CustomDropzone";

const ReportSelectCoverImage = () => {
    const { saveCoverImage } = useReportApi();
    const { images, setImages, fetchImages, saveImages } =
        useReportImageContext();
    const { deleteImagesFromRemote } = useImageApi();
    const { report, updateReport, setNextEnabled } = useReportContext();
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [coverImage, setCoverImage] = useState(
        report.coverImage ?? report.baseData.coverImage ?? null
    );

    function selectCoverImage(item) {
        setCoverImage(item.name);
        setNextEnabled(true);
    }

    useEffect(() => {
        setNextEnabled(coverImage != null);
        fetchImages(true);
    }, []);

    function deleteImage(imagesToDelete) {
        let tempFilesToUpload = [...filesToUpload];
        imagesToDelete
            .filter((image) => image instanceof File)
            .forEach((image) => {
                tempFilesToUpload = tempFilesToUpload.filter(
                    (file) => file.name !== image.name
                );
            });
        setFilesToUpload(tempFilesToUpload);
        const filteredImages = imagesToDelete.filter(
            (image) => !(image instanceof File)
        );
        if (filteredImages.length === 0) return;
        deleteImagesFromRemote(
            filteredImages,
            report.baseData.id,
            (response) => {
                setImages(
                    images.filter((img) => !response.data.includes(img.name))
                );
            }
        );
    }

    const onNextButtonClicked = async () => {
        return saveImages(filesToUpload).then((success) => {
            if (success) {
                return saveReportCoverImage();
            }
        });
    };

    const saveReportCoverImage = async () => {
        return saveCoverImage(report.baseData.id, coverImage, (response) => {
            updateReport("coverImage", response.data.coverImage);
        }).then(() => {
            return true;
        });
    };

    const handleSelectedFileToUpload = (uploadedFiles) => {
        console.log(uploadedFiles);
        const files = [];
        const imageNamesFromServer = images.map((file) => file.name);
        const filtered = Array.from(uploadedFiles).filter(
            (file) => !imageNamesFromServer.includes(file.name)
        );
        filtered.forEach((file) => {
            files.push(file);
        });
        setFilesToUpload(files);
    };

    return (
        <Stack>
            <Stack
                direction="row"
                spacing={2}
                m={2}
                justifyContent="space-between"
            >
                <Typography variant="h5">
                    Válasz jegyzőköny borító képet
                </Typography>
                <CustomDropzone
                    message="Dobj ide képet vagy klikk"
                    listFiles={false}
                    acceptedFileTypes={{
                        "image/png": [".png"],
                        "image/jpeg": [".jpg", ".jpeg"],
                        "image/heic": [".heic"],
                    }}
                    onFileListChange={(files) => {
                        handleSelectedFileToUpload(files);
                    }}
                />
            </Stack>
            <ImageGalleryWithDelete
                images={[...images, ...filesToUpload]}
                selectedImage={coverImage}
                showDelete={true}
                onDelete={(image) => {
                    deleteImage(image);
                }}
                onImageSelect={selectCoverImage}
            />
            <PrevNextButtons nextFunction={() => onNextButtonClicked()} />
        </Stack>
    );
};

export default ReportSelectCoverImage;
