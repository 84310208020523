export const ROLE = {
    SUPERADMIN: {
        code: 20154,
        name: "SuperAdmin",
    },
    ADMIN: {
        code: 1977,
        name: "Admin",
    },
    INSPECTOR: {
        code: 4677,
        name: "Ellenőr",
    },
    AUTHORITY: {
        code: 2845,
        name: "Hatóság",
    },
    USER: {
        code: 1,
        name: "Felhasználó",
    },
};

export const ROLES = [
    ROLE.SUPERADMIN,
    ROLE.ADMIN,
    ROLE.INSPECTOR,
    ROLE.AUTHORITY,
    ROLE.USER,
];

function matchRole(value, roleId) {
    return value.code === roleId;
}

export function getRoleById(roleId) {
    let match = Object.entries(ROLE).find(([key, value]) =>
        matchRole(value, roleId)
    );
    return match ? match[1].name : "";
}
