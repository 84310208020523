import { Box } from "@mui/system";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useReportApi } from "../../../api/reportApi";
import { getReportNumber } from "../../../utils/ReportUtil";
import useReportContext from "../useReportContext";
import { useQuickFilterWithFiltering } from "../../common/DiacriticSearch";

const columns = [
    {
        field: "number",
        headerName: "Sorszám",
        minWidth: 70,
        flex: 0.2,
    },
    {
        field: "shortName",
        headerName: "Rövid megnevezés",
        minWidth: 70,
        flex: 0.5,
    },
];

function QuickSearchToolbar({ quickFilterProps }) {
    return (
        <Box sx={{ p: 1 }}>
            <GridToolbarQuickFilter {...quickFilterProps} />
        </Box>
    );
}

export function ReportsByYearOverviewTable({ reportType, year }) {
    const { report, setReport } = useReportContext();
    const { getMyReportsByReportTypeAndYear } = useReportApi();
    const [currentYearReports, setCurrentYearReports] = useState([]);
    const [showTableLoading, setShowTableLoading] = useState(false);

    useEffect(() => {
        if (reportType) fetchCurrentYearReports();
    }, [reportType, year]);

    function fetchCurrentYearReports() {
        setShowTableLoading(true);
        getMyReportsByReportTypeAndYear(reportType, year, (response) => {
            setShowTableLoading(false);
            setCurrentYearReports(response.data.reports);
            if (report.baseData.number === "") {
                setReport((prevData) => ({
                    ...prevData,
                    baseData: {
                        ...prevData.baseData,
                        number: response.data.nextSuggestedReportId,
                    },
                }));
            }
        });
    }

    // Use the custom quick filter hook for multiple fields
    const { filteredRows, quickFilterProps } = useQuickFilterWithFiltering(
        currentYearReports,
        ["number", "shortName"]
    );

    return (
        <DataGrid
            rows={filteredRows}
            loading={showTableLoading}
            columns={columns}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableSelectionOnClick
            components={{ Toolbar: QuickSearchToolbar }}
            componentsProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: quickFilterProps,
                },
            }}
            density="compact"
        />
    );
}
