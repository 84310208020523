import TextField from "@mui/material/TextField";
import useOperatorContext from "./useOperatorContext";
import { Autocomplete, Box } from "@mui/material";

function OperatorEditDialogContent() {
    const { cities, data, handleChange, errors } = useOperatorContext();

    const uniqueCities = [...new Set(cities.map((city) => city.city))];

    return (
        <Box>
            <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-basic name"
                label="Név"
                variant="outlined"
                type="text"
                autoComplete="off"
                onChange={handleChange}
                value={data.name}
                error={errors.name != null}
                helperText={errors.name}
                name="name"
                required
            />
            <Autocomplete
                sx={{ width: "100%", my: 1 }}
                id="free-solo-demo"
                freeSolo
                name="zip"
                value={data.zip}
                onInputChange={(event, newInputValue) => {
                    if (event != null) {
                        event.target.name = "zip";
                        event.target.value = newInputValue;
                        handleChange(event);
                    }
                }}
                options={cities.map((city) => city.zip)}
                renderInput={(params) => (
                    <TextField {...params} label="Irányítószám" />
                )}
                required
            />
            <Autocomplete
                sx={{ width: "100%", my: 1 }}
                id="free-solo-demo"
                freeSolo
                name="city"
                value={data.city}
                onInputChange={(event, newInputValue) => {
                    if (event != null) {
                        event.target.name = "city";
                        handleChange(event);
                    }
                }}
                options={uniqueCities}
                renderInput={(params) => (
                    <TextField {...params} label="Város" />
                )}
                required
            />
            <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-basic email"
                label="Cím"
                multiline
                variant="outlined"
                type="text"
                autoComplete="off"
                onChange={handleChange}
                value={data.address}
                error={errors.address != null}
                helperText={errors.address}
                name="address"
                required
            />
            <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-basic email"
                label="Cégjegyzékszám / Adószám"
                multiline
                variant="outlined"
                type="text"
                autoComplete="off"
                onChange={handleChange}
                value={data.companyRegistrationNumber}
                error={errors.companyRegistrationNumber != null}
                helperText={errors.companyRegistrationNumber}
                name="companyRegistrationNumber"
                required
            />
            <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-basic email"
                label="Kapcsolattartó neve"
                multiline
                variant="outlined"
                type="text"
                autoComplete="off"
                onChange={handleChange}
                value={data.contactName}
                name="contactName"
            />
            <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-basic email"
                label="Kapcsolattartó email címe"
                multiline
                variant="outlined"
                type="text"
                autoComplete="off"
                onChange={handleChange}
                value={data.contactEmail}
                error={errors.contactEmail != null}
                helperText={errors.contactEmail}
                name="contactEmail"
            />
            <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-basic email"
                label="Kapcsolattartó telefonszáma"
                multiline
                variant="outlined"
                type="text"
                autoComplete="off"
                onChange={handleChange}
                value={data.contactPhoneNumber}
                name="contactPhoneNumber"
            />
            <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-basic email"
                label="Levelezési kapcsolattartó neve"
                multiline
                variant="outlined"
                type="text"
                autoComplete="off"
                onChange={handleChange}
                value={data.mailContactName}
                name="mailContactName"
            />
        </Box>
    );
}

export default OperatorEditDialogContent;
