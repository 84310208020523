export const ReportType = {
    PLAYGROUND: {
        code: "PLAYGROUND",
        urlparam: "playground",
        name: "Játszótér",
    },
    SPORT_EQUIPMENT: {
        code: "SPORT_EQUIPMENT",
        urlparam: "sportequipment",
        name: "Fitnesz eszköz",
    },
    ENTERTAINMENT: {
        code: "ENTERTAINMENT",
        urlparam: "entertainment",
        name: "Szórakoztató eszköz",
    },
};

export const ReportTypes = [
    ReportType.PLAYGROUND,
    ReportType.SPORT_EQUIPMENT,
    //ReportType.ENTERTAINMENT,
];

export function getReportTypeByCode(code) {
    switch (code) {
        case ReportType.PLAYGROUND.code:
            return ReportType.PLAYGROUND;
        case ReportType.SPORT_EQUIPMENT.code:
            return ReportType.SPORT_EQUIPMENT;
        case ReportType.ENTERTAINMENT.code:
            return ReportType.ENTERTAINMENT;
    }
}

export function getReportTypeByUrlParam(urlparam) {
    switch (urlparam) {
        case ReportType.PLAYGROUND.urlparam:
            return ReportType.PLAYGROUND;
        case ReportType.SPORT_EQUIPMENT.urlparam:
            return ReportType.SPORT_EQUIPMENT;
        case ReportType.ENTERTAINMENT.urlparam:
            return ReportType.ENTERTAINMENT;
    }
}
