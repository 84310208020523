import useFullScreenProgressBarContext from "../components/common/fullscreenprogressbar/useFullScreenProgressBarContext";
import useGeneralErrorContext from "../components/common/generalerrorsnackbar/useGeneralErrorContext";
import { IMAGE_URL } from "./Url";
import { axiosPrivate } from "./axios";

export function useImageApi() {
    const { setShowFullScreenProgressBar } = useFullScreenProgressBarContext();
    const { handleGeneralError } = useGeneralErrorContext();

    async function deleteImagesFromRemote(images, reportId, onSuccess) {
        setShowFullScreenProgressBar(true);
        const imageNames = images.map((image) => image.name);
        try {
            await axiosPrivate
                .post(
                    `${IMAGE_URL}/delete/report/${reportId}`,
                    JSON.stringify(imageNames),
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    }
                )
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    return {
        deleteImagesFromRemote,
    };
}
