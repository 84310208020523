// quickFilterUtils.js

import React from "react";

// Helper function to normalize strings (remove accents)
export const normalizeString = (str) =>
    str
        ?.normalize("NFD") // Normalize accents
        .replace(/[\u0300-\u036f]/g, "") // Remove accent marks
        .toLowerCase() || "";

// Custom hook to generate quick filter props and filtered rows
export function useQuickFilterWithFiltering(
    rows,
    filterFields,
    getFieldValue = (row, field) => {
        return row[field];
    },
    debounceMs = 500
) {
    const [quickFilterValue, setQuickFilterValue] = React.useState("");

    // Filter logic
    const filteredRows = React.useMemo(() => {
        if (!quickFilterValue) return rows; // No filter applied
        return rows.filter((row) =>
            filterFields.some((field) =>
                normalizeString(getFieldValue(row, field)).includes(
                    normalizeString(quickFilterValue)
                )
            )
        );
    }, [rows, filterFields, getFieldValue, quickFilterValue]);

    // Quick filter props
    const quickFilterProps = {
        debounceMs,
        value: quickFilterValue,
        onChange: (event) => setQuickFilterValue(event.target.value),
    };

    return { filteredRows, quickFilterProps };
}
