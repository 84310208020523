export class ReportStep {
    static BaseData = new ReportStep("BaseData", "Alapadatok");
    static CoverImage = new ReportStep("CoverImage", "Képfeltöltés");
    static Location = new ReportStep("Location", "Helyszín");
    static Equipments = new ReportStep("Equipments", "Eszközök");
    static EntertainmentEquipment = new ReportStep(
        "EntertainmentEquipment",
        "Eszköz"
    );
    static Restrictions = new ReportStep("Restrictions", "Korlátozások");
    static EquipmentDetails = new ReportStep(
        "EquipmentDetails",
        "Adatfeltöltés"
    );
    static Generate = new ReportStep("Generate", "Dokumentum generálás");

    constructor(name, label) {
        this.name = name;
        this.title = label;
    }
    toString() {
        return `ReportStep.${this.name}`;
    }
}
