import {
    DataGrid,
    GridActionsCellItem,
    GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
    Autocomplete,
    Box,
    Button,
    List,
    ListItem,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import usePlaygroundReportEquipmentsByManufacturerContext from "./usePlaygroundReportEquipmentsContext";
import { useState } from "react";
import usePlaygroundReportEquipmentsContext from "../usePlaygroundReportEquipmentsContext";
import EditIcon from "@mui/icons-material/Edit";
import ManufacturerEditDialog from "../../../basicdata/manufacturer/ManufacturerEditDialog";
import useManufacturerContext from "../../../basicdata/manufacturer/useManufacturerContext";
import useEquipmentContext from "../../../basicdata/equipment/useEquipmentContext";
import EquipmentEditDialog from "../../../basicdata/equipment/EquipmentEditDialog";
import { CustomTabPanel } from "../../../common/CustomTabPanel";
import { useQuickFilterWithFiltering } from "../../../common/DiacriticSearch";
import useReportContext from "../../useReportContext";

const countMap = new Map();

function QuickSearchToolbar({ quickFilterProps }) {
    return (
        <Box sx={{ p: 1 }}>
            <GridToolbarQuickFilter {...quickFilterProps} />
        </Box>
    );
}

function PlaygroundReportEquipmentsByManufacturer() {
    const {
        equipments,
        manufacturers,
        equipmentGroups,
        fetchManufacturers,
        showTableLoading,
        selectedManufacturer,
        setSelectedManufacturer,
        fetchEquipmentsByManufacturer,
        addEquipmentsFromEquipmentGroup,
    } = usePlaygroundReportEquipmentsByManufacturerContext();
    const { addEquipments, setEquipmentSelection } =
        usePlaygroundReportEquipmentsContext();
    const [persistentSelection, setPersistentSelection] = useState(new Set());
    const { setShowNewManufacturerDialog } = useManufacturerContext();
    const { reportConfig } = useReportContext();
    const { selectEquipment, setShowNewEquipmentDialog } =
        useEquipmentContext();

    const columns = [
        { field: "name", headerName: "Név", minWidth: 70, flex: 0.2 },
        {
            field: "typeNumber",
            headerName: "Cikkszám",
            minWidth: 130,
            flex: 0.2,
        },
        {
            field: "certificateNumber",
            headerName: "Tanúsítvány szám",
            minWidth: 130,
            flex: 0.2,
        },
        {
            field: "count",
            headerName: "Darabszám",
            minWidth: 50,
            type: "number",
            flex: 0.1,
            editable: true,
            valueGetter: (params) => countMap[params.id] ?? 1,
        },
        {
            field: "actions",
            type: "actions",
            headerName: "",
            width: 50,
            cellClassName: "actions",
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Szerkesztés"
                        onClick={() => handleEditClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    const handleSelectionChange = (newSelectionModel) => {
        const updatedSelection = new Set(persistentSelection);

        // Add newly selected items
        newSelectionModel.forEach((id) => updatedSelection.add(id));

        // Detect deselected items (from visible selection)
        const previouslyVisibleSelected = Array.from(
            persistentSelection
        ).filter((id) => filteredRows.some((row) => row.id === id));

        previouslyVisibleSelected.forEach((id) => {
            if (!newSelectionModel.includes(id)) {
                updatedSelection.delete(id);
            }
        });

        setPersistentSelection(updatedSelection);
    };

    function handleEditClick(id) {
        selectEquipment(id);
        setShowNewEquipmentDialog(true);
    }

    const handleAdd = () => {
        const selected = equipments.filter((item) =>
            persistentSelection.has(item.id)
        );
        addEquipments(selected);
        setEquipmentSelection(false);
    };

    const handleBack = () => {
        setEquipmentSelection(false);
    };

    const onCountEditCommit = (params, event) => {
        countMap[params.id] = parseInt(params.value);
    };

    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedEquipmentGroup, setSelectedEquipmentGroup] = useState(null);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    // Use the custom quick filter hook for multiple fields
    const { filteredRows, quickFilterProps } = useQuickFilterWithFiltering(
        equipments,
        ["name", "typeNumber", "certificateNumber"]
    );

    const visibleSelectionModel = Array.from(persistentSelection).filter((id) =>
        filteredRows.some((row) => row.id === id)
    );

    return (
        <div style={{ height: 500, width: "100%" }}>
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="basic tabs example"
            >
                <Tab label="Egyenként" />
                <Tab label="Eszközcsoport" />
            </Tabs>
            <CustomTabPanel value={selectedTab} index={0}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Autocomplete
                        sx={{ width: "87%", my: 2 }}
                        id="free-solo-demo"
                        name="manufacturer"
                        value={selectedManufacturer}
                        onChange={(event, newInputValue) => {
                            if (event != null) {
                                event.target.type = "autocomplete";
                                event.target.name = "manufacturer";
                                event.target.selectedOption = newInputValue;
                                setSelectedManufacturer(newInputValue);
                            }
                        }}
                        options={manufacturers}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField {...params} label="Gyártó" />
                        )}
                    />
                    <Button
                        variant="outlined"
                        name="new"
                        onClick={() => setShowNewManufacturerDialog(true)}
                    >
                        Új gyártó
                    </Button>
                </Stack>
                <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
                    <Button
                        sx={{ mb: 2 }}
                        disabled={!selectedManufacturer}
                        variant="contained"
                        name="new"
                        onClick={() => setShowNewEquipmentDialog(true)}
                    >
                        Új eszköz
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button
                        sx={{ mb: 2 }}
                        variant="contained"
                        onClick={handleAdd}
                        disabled={persistentSelection.length === 0}
                    >
                        Hozzáadás
                    </Button>
                </Box>
            </CustomTabPanel>
            <CustomTabPanel value={selectedTab} index={1}>
                <Autocomplete
                    sx={{ width: "100%", my: 2 }}
                    id="free-solo-demo"
                    name="equipmentgroup"
                    autoHighlight
                    value={selectedEquipmentGroup}
                    disabled={equipmentGroups.length === 0}
                    onChange={(event, newInputValue) => {
                        if (event != null) {
                            setSelectedEquipmentGroup(newInputValue);
                        }
                    }}
                    options={equipmentGroups}
                    renderOption={(props, option) => (
                        <ListItem {...props}>
                            <List>
                                <ListItem disablePadding>
                                    <Typography variant="h6" sx={{ ml: 0 }}>
                                        {option.name}
                                    </Typography>
                                </ListItem>
                                <Typography variant="body2" sx={{ ml: 0 }}>
                                    {option.description}
                                </Typography>
                            </List>
                        </ListItem>
                    )}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField {...params} label="Eszközcsoport" />
                    )}
                />
                <Button
                    sx={{ mb: 2 }}
                    variant="contained"
                    name="addAllByGroup"
                    disabled={
                        equipmentGroups.length === 0 ||
                        selectedEquipmentGroup == null
                    }
                    onClick={() =>
                        addEquipmentsFromEquipmentGroup(
                            selectedEquipmentGroup.equipments
                        )
                    }
                >
                    Eszközök hozzáadása
                </Button>
            </CustomTabPanel>
            <DataGrid
                rows={filteredRows}
                loading={showTableLoading}
                columns={columns}
                pageSize={Math.min(100, equipments.length)}
                checkboxSelection
                rowsPerPageOptions={[Math.min(100, equipments.length)]}
                onCellEditCommit={onCountEditCommit}
                disableColumnSelector
                selectionModel={visibleSelectionModel}
                onSelectionModelChange={handleSelectionChange}
                components={{ Toolbar: QuickSearchToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: quickFilterProps,
                    },
                }}
            />
            <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
                <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
                    Vissza
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button
                    variant="contained"
                    onClick={handleAdd}
                    disabled={persistentSelection.length === 0}
                >
                    Hozzáadás
                </Button>
            </Box>
            <ManufacturerEditDialog
                onClose={(manufacturer) => {
                    fetchManufacturers().then(() => {
                        setSelectedManufacturer(manufacturer);
                    });
                }}
            />
            <EquipmentEditDialog
                manufacturer={selectedManufacturer}
                equipmentCategory={reportConfig.equipmentCategory}
                onClose={(equipment) => {
                    fetchEquipmentsByManufacturer(selectedManufacturer);
                }}
            />
        </div>
    );
}

export default PlaygroundReportEquipmentsByManufacturer;
