import { createContext, useState, useLayoutEffect, useEffect } from "react";
import { useEquipmentApi } from "../../../api/equipmentApi";
import { useEquipmentCategoryApi } from "../../../api/equipmentCategoryApi";
import { useEquipmentGroupApi } from "../../../api/equipmentGroupApi";
import { useManufacturerApi } from "../../../api/manufacturerApi";

const emptyEquipmentGroup = {
    id: null,
    name: null,
    description: null,
    category: null,
    equipments: [],
};

const emptyErrors = {
    name: null,
};

const EquipmentGroupContext = createContext({});

export const EquipmentGroupProvider = ({ children }) => {
    const { getEquipmentGroups, saveEquipmentGroup, deleteEquipmentGroup } =
        useEquipmentGroupApi();
    const { getEquipments } = useEquipmentApi();
    const { getEquipmentCategories } = useEquipmentCategoryApi();
    const { getManufacturers } = useManufacturerApi();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const [equipmentGroups, setEquipmentGroup] = useState([]);

    const [equipmentCategories, setEquipmentCategories] = useState([]);
    const [manufacturers, setManufacturers] = useState([]);
    const [selectedManufacturer, setSelectedManufacturer] = useState(null);
    const [equipments, setEquipments] = useState([]);

    const [errors, setErrors] = useState(emptyErrors);

    const [data, setData] = useState(emptyEquipmentGroup);

    const hasError =
        !data.name || errors.name || !data.category || errors.category;

    const showTableEditButtons = data.id === null ? false : true;

    function handleEquipmentListChange(name, value) {
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const handleChange = (e) => {
        const type = e.target.type;

        const name = e.target.name;

        let value;
        switch (type) {
            case "checkbox": {
                value = e.target.checked;
                break;
            }
            case "file": {
                value = e.target.files[0];
                break;
            }
            case "autocomplete": {
                value = e.target.selectedOption;
                break;
            }
            default: {
                value = e.target.value;
                break;
            }
        }

        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {
        fetchEquipmentGroups();
        fetchEquipmentCategories();
        fetchManufacturers();
    }, []);

    useEffect(() => {
        fetchEquipmentsIfNeeded();
    }, [selectedManufacturer, setSelectedManufacturer]);

    useEffect(() => {
        fetchEquipmentsIfNeeded();
    }, [data, setData]);

    function setSelectedEquipmentGroup(id) {
        if (id != null) {
            const standard = equipmentGroups.find(
                (standard) => standard.id === id
            );
            setData(standard);
        } else {
            setData(emptyEquipmentGroup);
        }
    }

    useLayoutEffect(() => {
        if (!showDialog) {
            setErrors(emptyErrors);
        }
    }, [showDialog]);

    const fetchEquipmentsIfNeeded = () => {
        const needToFetchEquipments =
            selectedManufacturer != null && data.category != null;
        if (needToFetchEquipments) fetchEquipments();
    };

    function fetchEquipmentGroups() {
        getEquipmentGroups((response) => {
            setEquipmentGroup(response.data.equipmentGroups);
        });
    }

    function fetchEquipmentCategories() {
        getEquipmentCategories((response) => {
            setEquipmentCategories(response.data.equipmentCategories);
        });
    }

    function fetchManufacturers() {
        getManufacturers((response) => {
            setManufacturers(response.data.manufacturers);
        });
    }

    function fetchEquipments() {
        getEquipments((response) => {
            setEquipments(response.data.equipments);
        });
    }

    function save() {
        saveEquipmentGroup(data, (response) => {
            fetchEquipmentGroups();
            setShowDialog(false);
        });
    }

    function deleteEquipGroup() {
        deleteEquipmentGroup(data.id, (response) => {
            fetchEquipmentGroups();
        });
    }

    return (
        <EquipmentGroupContext.Provider
            value={{
                data,
                equipments,
                showDialog,
                setShowDialog,
                showDeleteConfirm,
                setShowDeleteConfirm,
                equipmentGroups,
                setEquipmentGroup,
                errors,
                hasError,
                handleChange,
                handleEquipmentListChange,
                save,
                deleteEquipGroup,
                showTableEditButtons,
                equipmentCategories,
                manufacturers,
                selectedManufacturer,
                setSelectedManufacturer,
                setSelectedEquipmentGroup,
            }}
        >
            {children}
        </EquipmentGroupContext.Provider>
    );
};

export default EquipmentGroupContext;
