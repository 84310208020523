import { useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Checkbox from "@mui/material/Checkbox";
import { Box, Button, useTheme } from "@mui/material";
import AlertDialog from "./AlertDialog";

const ImageGalleryWithDelete = ({
    images,
    selectedImage,
    showDelete,
    onDelete,
    onImageSelect,
}) => {
    const theme = useTheme();
    const [selectedImagesForDelete, setSelectedImagesForDelete] = useState([]);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const handleImageSelect = (event, image, index) => {
        event.stopPropagation();
        if (onImageSelect) {
            onImageSelect(image, index);
        }
    };

    const handleCheckboxChange = (event, image) => {
        setSelectedImagesForDelete((prevSelected) => {
            if (event.target.checked) {
                return [...prevSelected, image];
            } else {
                return prevSelected.filter((img) => img !== image);
            }
        });
    };

    const handleDeleteConfirm = (positive) => {
        if (positive && onDelete) {
            onDelete(selectedImagesForDelete);
        }
        setShowDeleteConfirm(false);
        setSelectedImagesForDelete([]);
    };

    const handleDeleteClick = () => {
        if (selectedImagesForDelete.length > 0) {
            setShowDeleteConfirm(true);
        }
    };

    return (
        <div>
            {showDelete && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mb: 2,
                        mr: 2,
                    }}
                >
                    <Button
                        onClick={handleDeleteClick}
                        disabled={selectedImagesForDelete.length === 0}
                        variant="contained"
                    >
                        Kijelölt képek törlése
                    </Button>
                </Box>
            )}
            <ImageList variant="quilted" cols={4} gap={4}>
                {images.map((image, index) => (
                    <ImageListItem
                        key={index}
                        style={{ position: "relative" }}
                        sx={{
                            border: selectedImage === image?.name ? 5 : 0,
                            borderColor: theme.palette.primary.main,
                            p: selectedImage === image?.name ? 0 : 0,
                        }}
                    >
                        <img
                            src={
                                image instanceof File
                                    ? URL.createObjectURL(image)
                                    : `data:image/jpeg;base64, ${image.thumbnail}`
                            }
                            alt={`Image ${index}`}
                            style={{ width: "100%", height: "100%" }}
                            onClick={(event) =>
                                handleImageSelect(event, image, index)
                            }
                        />
                        {showDelete && selectedImage !== image?.name && (
                            <ImageListItemBar
                                position="top"
                                style={{ background: "transparent" }}
                                actionIcon={
                                    <Checkbox
                                        edge="end"
                                        color="default"
                                        checked={selectedImagesForDelete.includes(
                                            image
                                        )}
                                        onChange={(e) =>
                                            handleCheckboxChange(e, image)
                                        }
                                        sx={{
                                            mr: 1,
                                            mt: 1,
                                            color: "#fff",
                                            backgroundColor:
                                                "rgba(0, 0, 0, 0.6)",
                                            borderRadius: "50%",
                                            "&:hover": {
                                                backgroundColor:
                                                    "rgba(0, 0, 0, 0.8)",
                                            },
                                        }}
                                    />
                                }
                            />
                        )}
                    </ImageListItem>
                ))}
            </ImageList>
            <AlertDialog
                open={[showDeleteConfirm, setShowDeleteConfirm]}
                message="Törlöd a kijelölt képeket?"
                positiveButton="Törlés"
                negativeButton="Mégse"
                buttonHandler={handleDeleteConfirm}
            />
        </div>
    );
};

export default ImageGalleryWithDelete;
