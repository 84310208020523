import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useGeneratedReportApi } from "../../../api/generatedReportApi";
import { useEffect, useState } from "react";
import PageTitleWithBackButton from "../../common/PageTitleWithBackButton";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import AlertDialog from "../../common/AlertDialog";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { GridCellWithPopover } from "../../common/GridCellWithPopover";
import CustomSnackbar from "../../common/CustomSnackBar";
import { useQuickFilterWithFiltering } from "../../common/DiacriticSearch";

const ReportSendPage = ({}) => {
    const {
        getUnsentGeneratedReports,
        sendGeneratedReports,
        deleteGeneratedReports,
        downloadGeneratedReport,
    } = useGeneratedReportApi();
    const [unsentReports, setUnsentReports] = useState([]);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showSendConfirm, setShowSendConfirm] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [emailSnackbar, setEmailSnackbar] = useState(false);

    useEffect(() => {
        fetchUnsentReports();
    }, []);

    function fetchUnsentReports() {
        getUnsentGeneratedReports((response) => {
            setUnsentReports(response.data.generatedreports);
        });
    }

    function handleViewPdfClick(id) {
        downloadGeneratedReport(id, (response) => {
            const fileURL = URL.createObjectURL(response.data);
            window.open(fileURL);
        });
    }

    const customPopoverContent = (params) => (
        <Box sx={{ p: 1, maxWidth: 400 }}>
            <Typography variant="h7">{params.row.authorityName}</Typography>

            <Typography sx={{ mt: 2 }} variant="body1">
                {params.row.authorityAddress}
            </Typography>
            <Typography sx={{ mt: 2 }} variant="body1">
                {params.row.authorityAdministrator}
            </Typography>
        </Box>
    );

    const columns = [
        {
            field: "reportId",
            headerName: "Jegyzőkönyv száma",
            minWidth: 70,
            flex: 0.1,
        },
        {
            field: "shortName",
            headerName: "Rövid megnevezés",
            minWidth: 70,
            flex: 0.2,
        },
        {
            field: "inspectionDate",
            headerName: "Vizsgálat dátuma",
            minWidth: 70,
            flex: 0.1,
            valueGetter: (params) =>
                dayjs(params.row.inspectionDate).format("YYYY.MM.DD."),
        },
        {
            field: "countryName",
            headerName: "Vármegye",
            minWidth: 70,
            flex: 0.2,
            renderCell: (params) => {
                return (
                    <GridCellWithPopover
                        value={params.value}
                        popoverContent={customPopoverContent(params)}
                    />
                );
            },
        },
        {
            field: "email",
            headerName: "Email",
            minWidth: 70,
            flex: 0.2,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.authorityEmailAddresses.map((email) => (
                            <div key={email}>
                                <a
                                    href={`mailto:${email}`}
                                    style={{
                                        color: "inherit",
                                    }}
                                >
                                    {email}
                                </a>
                            </div>
                        ))}
                    </div>
                );
            },
        },
        {
            field: "actions",
            type: "actions",
            headerName: "PDF",
            width: 90,
            cellClassName: "actions",
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<PictureAsPdfIcon />}
                        label="Megtekintés"
                        onClick={() => handleViewPdfClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    function handleSendClick() {
        setShowSendConfirm(true);
    }

    function handleDeleteClick() {
        setShowDeleteConfirm(true);
    }

    function handleDeleteConfirm(positive) {
        if (positive) {
            deleteGeneratedReports(selectedRows, (response) => {
                fetchUnsentReports();
            });
        }
    }

    function handleSendConfirm(positive) {
        if (positive) {
            sendGeneratedReports(selectedRows, (response) => {
                setEmailSnackbar(true);
                fetchUnsentReports();
            });
        }
    }

    // Use the custom quick filter hook for multiple fields
    const { filteredRows, quickFilterProps } = useQuickFilterWithFiltering(
        unsentReports,
        ["reportId", "shortName", "countryName", "email"]
    );

    return (
        <Box sx={{ mt: 2 }}>
            <PageTitleWithBackButton title="Küldés hatóságnak" />
            <Stack direction="row" spacing={2} m={2}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Button
                        sx={{ mx: 1 }}
                        variant="contained"
                        name="edit"
                        disabled={selectedRows.length === 0}
                        startIcon={<SendIcon />}
                        onClick={handleSendClick}
                    >
                        Küldés
                    </Button>
                    <Button
                        sx={{ mx: 1 }}
                        variant="outlined"
                        name="delete"
                        disabled={selectedRows.length === 0}
                        startIcon={<DeleteIcon />}
                        onClick={handleDeleteClick}
                    >
                        Törlés
                    </Button>
                </Box>
            </Stack>
            <DataGrid
                rows={filteredRows}
                columns={columns}
                autoHeight={true}
                checkboxSelection
                onSelectionModelChange={setSelectedRows}
                pageSize={Math.min(100, unsentReports.length)}
                rowsPerPageOptions={[Math.min(100, unsentReports.length)]}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps,
                    },
                }}
            />
            <AlertDialog
                open={[showDeleteConfirm, setShowDeleteConfirm]}
                message="Törlöd a kijelölteket?"
                positiveButton="Törlés"
                negativeButton="Mégse"
                buttonHandler={handleDeleteConfirm}
            />
            <CustomSnackbar
                message={`Jegyzőkönyvek elküldve`}
                visible={[emailSnackbar, setEmailSnackbar]}
            />
            <AlertDialog
                open={[showSendConfirm, setShowSendConfirm]}
                message="Elküldöd a kijelölteket?"
                positiveButton="Küldés"
                negativeButton="Mégse"
                buttonHandler={handleSendConfirm}
            />
        </Box>
    );
};

export default ReportSendPage;
