import App from "./App";
import { AuthProvider } from "./context/AuthProvider";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import { ROLE } from "./utils/Roles";

const container = document.getElementById("root");

const root = createRoot(container);

function AppRoutes() {
    const { auth } = useAuth();

    return (
        <Routes>
            {auth?.role === ROLE.AUTHORITY.code ? (
                // Redirect to /authority/list for AUTHORITY role
                <>
                    <Route path="/*" element={<App />} />
                    <Route
                        path="*"
                        element={<Navigate to="/authority/list" />}
                    />
                </>
            ) : (
                // Default route for other roles
                <>
                    <Route path="*" element={<Navigate to="/" />} />
                </>
            )}
        </Routes>
    );
}

root.render(
    <BrowserRouter>
        <AuthProvider>
            <Routes>
                <Route path="/*" element={<App />} />
            </Routes>
        </AuthProvider>
    </BrowserRouter>
);
