import useFullScreenProgressBarContext from "../components/common/fullscreenprogressbar/useFullScreenProgressBarContext";
import useGeneralErrorContext from "../components/common/generalerrorsnackbar/useGeneralErrorContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { ROLE } from "../utils/Roles";
import { USER_URL } from "./Url";

export function useUserApi() {
    const { setShowFullScreenProgressBar } = useFullScreenProgressBarContext();
    const { handleGeneralError } = useGeneralErrorContext();
    const axiosPrivate = useAxiosPrivate();

    async function changePassword(id, oldPassword, newPassword, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            const request = {
                id: id,
                oldPassword: oldPassword,
                newPassword: newPassword,
            };
            await axiosPrivate
                .post(
                    `${USER_URL}/password/change/${id}`,
                    JSON.stringify(request),
                    {
                        withCredentials: true,
                    }
                )
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function fetchUsers(onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .get(`${USER_URL}/list`, {
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function saveUser(data, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            const formData = new FormData();
            if (data.id != null) {
                formData.append("id", data.id);
            }
            formData.append("File", data.signature);
            formData.append("email", data.email);
            formData.append("firstName", data.firstName);
            formData.append("lastName", data.lastName);
            formData.append("role", data.role);
            formData.append("active", data.active);
            if (data.role !== ROLE.AUTHORITY.code) {
                formData.append("reportVerifier", data.reportVerifier);
            }

            await axiosPrivate
                .post(`${USER_URL}`, formData, {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function deleteUser(id, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .delete(`${USER_URL}/${id}`, {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function requestPassword(id, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .get(`${USER_URL}/password/new/${id}`, {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function downloadSignature(id) {
        setShowFullScreenProgressBar(true);
        try {
            const response = await axiosPrivate.get(
                `${USER_URL}/${id}/download`,
                {
                    responseType: "blob",
                    withCredentials: true,
                }
            );
            const href = URL.createObjectURL(response.data);
            const link = document.createElement("a");
            const fileName = response.headers["content-disposition"]
                .split("filename=")[1]
                .replaceAll('"', "");
            link.href = href;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    return {
        fetchUsers,
        saveUser,
        deleteUser,
        requestPassword,
        downloadSignature,
        changePassword,
    };
}
