import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useEquipmentContext from "./useEquipmentContext";
import { useQuickFilterWithFiltering } from "../../common/DiacriticSearch";

const getEquipmentTypeValue = (row) => {
    if (row.type) {
        return `${row.category.name} / ${row.type.name}`;
    } else {
        return row.category.name;
    }
};

const getManufacturerName = (row) => {
    return row.manufacturer?.name ?? "";
};

const columns = [
    { field: "name", headerName: "Név", minWidth: 70, flex: 0.3 },
    { field: "typeNumber", headerName: "Cikkszám", minWidth: 130, flex: 0.1 },
    {
        field: "manufacturer",
        headerName: "Gyártó",
        minWidth: 70,
        flex: 0.2,
        valueGetter: (params) => getManufacturerName(params.row),
    },
    {
        field: "certificateNumber",
        headerName: "Tanúsítvány szám",
        minWidth: 130,
        flex: 0.2,
    },
    {
        field: "type",
        headerName: "Típus",
        minWidth: 130,
        flex: 0.2,
        valueGetter: (params) => getEquipmentTypeValue(params.row),
    },
];

// Function to get a field's value for filtering
const getFieldValue = (row, field) => {
    if (field === "manufacturer") return row.manufacturer?.name; // Handle valueGetter for manufacturer
    return row[field]; // Default direct field access
};

function EquipmentTable() {
    const { equipments, selectEquipment } = useEquipmentContext();

    function selectionModelChanged(ids) {
        const id = ids.length !== 0 ? ids[0] : null;
        selectEquipment(id);
    }

    // Use the custom quick filter hook for multiple fields
    const { filteredRows, quickFilterProps } = useQuickFilterWithFiltering(
        equipments,
        ["name", "manufacturer"], // Specify all searchable fields
        getFieldValue
    );

    return (
        <div style={{ height: 500, width: "100%" }}>
            <DataGrid
                rows={filteredRows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps,
                    },
                }}
                onSelectionModelChange={(ids) => selectionModelChanged(ids)}
            />
        </div>
    );
}

export default EquipmentTable;
