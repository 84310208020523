import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ReplayIcon from "@mui/icons-material/Replay";
import SendIcon from "@mui/icons-material/Send";
import { ROLE } from "../../utils/Roles";
import useAuth from "../../hooks/useAuth";

const PlaygroundReportCard = () => {
    const { auth } = useAuth();
    return (
        <Box>
            <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{
                    backgroundColor: "primary.main", // using the theme's primary color
                    color: "primary.contrastText", // ensuring text is readable on the primary color
                    padding: "8px", // adding some padding
                    borderRadius: "4px", // optional rounded corners
                }}
            >
                Játszótér
            </Typography>
            <List>
                <ListItem disablePadding>
                    <ListItemButton component="a" href="/report/playground">
                        <ListItemIcon>
                            <AddIcon />
                        </ListItemIcon>
                        <ListItemText primary="Új" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton
                        component="a"
                        href="/report/playground/list/return"
                    >
                        <ListItemIcon>
                            <ReplayIcon />
                        </ListItemIcon>
                        <ListItemText primary="Visszatérő helyszínek" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton
                        component="a"
                        href="/report/playground/list"
                    >
                        <ListItemIcon>
                            <EditIcon />
                        </ListItemIcon>
                        <ListItemText primary="Módosítás" />
                    </ListItemButton>
                </ListItem>
                {[ROLE.ADMIN.code, ROLE.SUPERADMIN.code]?.includes(
                    auth?.role
                ) && (
                    <ListItem disablePadding>
                        <ListItemButton
                            component="a"
                            href="/report/playground/send"
                        >
                            <ListItemIcon>
                                <SendIcon />
                            </ListItemIcon>
                            <ListItemText primary="Küldés hatóságnak" />
                        </ListItemButton>
                    </ListItem>
                )}
            </List>
        </Box>
    );
};

export default PlaygroundReportCard;
