import { Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getRoleById } from "../../../utils/Roles";
import useAdminUserContext from "./useAdminUserContext";
import { useQuickFilterWithFiltering } from "../../common/DiacriticSearch";

function AdminUserTable() {
    const { users, selectUser, downloadSignature } = useAdminUserContext();

    const columns = [
        { field: "email", headerName: "Email", minWidth: 70, flex: 0.2 },
        {
            field: "lastName",
            headerName: "Vezetéknév",
            minWidth: 130,
            flex: 0.2,
        },
        {
            field: "firstName",
            headerName: "Keresztnév",
            minWidth: 130,
            flex: 0.1,
        },
        {
            field: "role",
            headerName: "Szerepkör",
            minWidth: 130,
            flex: 0.2,
            valueGetter: (params) => getRoleById(params.row.role),
        },
        {
            field: "active",
            headerName: "Aktív",
            type: "boolean",
            minWidth: 90,
            flex: 0.1,
        },
        {
            field: "hasPassword",
            headerName: "Van jelszó",
            type: "boolean",
            minWidth: 90,
            flex: 0.1,
        },
        {
            field: "signature",
            headerName: "Aláírás",
            minWidth: 70,
            flex: 0.1,
            renderCell: (params) =>
                params.row.hasSignature ? (
                    <strong>
                        <Button
                            variant="text"
                            onClick={() => downloadSignature(params.row.id)}
                        >
                            Letöltés
                        </Button>
                    </strong>
                ) : (
                    ""
                ),
        },
    ];

    function selectionModelChanged(ids) {
        const id = ids.length !== 0 ? ids[0] : null;
        rowSelected(id);
    }

    function rowSelected(id) {
        selectUser(id);
    }

    // Use the custom quick filter hook for multiple fields
    const { filteredRows, quickFilterProps } = useQuickFilterWithFiltering(
        users,
        ["email", "lastName", "firstName"]
    );

    return (
        <div style={{ height: 400, width: "100%" }}>
            <DataGrid
                rows={filteredRows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                onSelectionModelChange={(ids) => selectionModelChanged(ids)}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps,
                    },
                }}
            />
        </div>
    );
}

export default AdminUserTable;
