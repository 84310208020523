import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";
import useGeneralErrorContext from "./useGeneralErrorContext";
import AlertDialog from "../AlertDialog";

/* 
    severity:
    error
    warning
    info
    success 
*/

export default function GeneralErrorSnackBar() {
    const { showGeneralError, setShowGeneralError, message } =
        useGeneralErrorContext();

    const handleClose = () => {
        setShowGeneralError(false);
    };

    return (
        <div>
            {showGeneralError && (
                <AlertDialog
                    open={[showGeneralError, setShowGeneralError]}
                    title="Hiba"
                    message={message}
                    positiveButton="OK"
                    buttonHandler={handleClose}
                />
            )}
        </div>
    );
}
