import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useContext, useReducer } from "react";
import { appbarReducer, APPBAR_ACTION_TYPES } from "./appbarReducer";
import { LOGIN_INITIAL_STATE } from "../login/loginReducer";
import useLogout from "../../hooks/useLogout";
import { FormControlLabel, Switch } from "@mui/material";
import ColorModeContext from "../../context/ToggleColorMode";
import { useTheme } from "@mui/material/styles";
import { ROLE } from "../../utils/Roles";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
        margin: 1,
        padding: 0,
        transform: "translateX(6px)",
        "&.Mui-checked": {
            color: "#fff",
            transform: "translateX(22px)",
            "& .MuiSwitch-thumb:before": {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    "#fff"
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor:
                    theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
            },
        },
    },
    "& .MuiSwitch-thumb": {
        backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
        width: 32,
        height: 32,
        "&::before": {
            content: "''",
            position: "absolute",
            width: "100%",
            height: "100%",
            left: 0,
            top: 0,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                "#fff"
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    "& .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
        borderRadius: 20 / 2,
    },
}));

const PAGE_NAME = "JÁTSZÓTÉR ŐR";
const profile_menu_items = {
    PROFILE: "Profil",
    ADMIN: "Admin",
    LOGOUT: "Kijelentkezés",
};
const pages = [];
const settings = [profile_menu_items.ADMIN, profile_menu_items.LOGOUT];

const ResponsiveAppBar = () => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === "dark";
    const colorMode = useContext(ColorModeContext);
    const navigate = useNavigate();
    const logout = useLogout();

    const [state, dispatch] = useReducer(appbarReducer, LOGIN_INITIAL_STATE);
    const { auth } = useAuth();
    const isMenuVisible = auth?.accessToken ? true : false;

    // Filter settings dynamically based on role
    const filteredSettings = settings.filter((setting) => {
        if (
            setting === profile_menu_items.ADMIN &&
            auth?.role !== ROLE.ADMIN.code &&
            auth?.role !== ROLE.SUPERADMIN.code
        ) {
            return false; // Exclude ADMIN if role is AUTHORITY
        }
        return true;
    });

    const signOut = async () => {
        await logout();
        navigate("/login");
    };

    const handleOpenNavMenu = (event) => {
        dispatch({
            type: APPBAR_ACTION_TYPES.OPEN_NAV_MENU,
            payload: event.currentTarget,
        });
    };

    const handleOpenUserMenu = (event) => {
        dispatch({
            type: APPBAR_ACTION_TYPES.OPEN_USER_MENU,
            payload: event.currentTarget,
        });
    };

    const handleCloseNavMenu = () => {
        dispatch({ type: APPBAR_ACTION_TYPES.CLOSE_NAV_MENU, payload: null });
    };

    const handleCloseUserMenu = (setting) => {
        dispatch({ type: APPBAR_ACTION_TYPES.CLOSE_USER_MENU, payload: null });
        switch (setting) {
            case profile_menu_items.ADMIN:
                navigate("/admin");
                break;
            case profile_menu_items.LOGOUT:
                signOut();
                break;
            default:
                break;
        }
    };

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: "none", md: "flex" },
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                        }}
                    >
                        {PAGE_NAME}
                    </Typography>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "flex", md: "none" },
                        }}
                    >
                        <Menu
                            id="menu-appbar"
                            anchorEl={state.anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(state.anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem
                                    key={page}
                                    onClick={handleCloseNavMenu}
                                >
                                    <Typography textAlign="center">
                                        {page}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: "flex", md: "none" },
                            flexGrow: 1,
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                        }}
                    >
                        {PAGE_NAME}
                    </Typography>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "none", md: "flex" },
                        }}
                    >
                        {pages.map((page) => (
                            <Button
                                key={page}
                                sx={{ my: 2, color: "white", display: "block" }}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>

                    {isMenuVisible && (
                        <Box sx={{ flexGrow: 0 }}>
                            <FormControlLabel
                                control={
                                    <MaterialUISwitch
                                        sx={{ m: 1 }}
                                        checked={isDarkMode}
                                        onChange={colorMode.toggleColorMode}
                                    />
                                }
                            />
                            <Tooltip title="Open settings">
                                <IconButton
                                    onClick={handleOpenUserMenu}
                                    sx={{ p: 0 }}
                                >
                                    <Avatar src="/static/images/avatar/2.jpg" />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: "45px" }}
                                id="menu-appbar"
                                anchorEl={state.anchorElUser}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(state.anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {filteredSettings.map((setting) => (
                                    <MenuItem
                                        key={setting}
                                        onClick={() =>
                                            handleCloseUserMenu(setting)
                                        }
                                    >
                                        <Typography textAlign="center">
                                            {setting}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default ResponsiveAppBar;
