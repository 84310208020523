import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import PivotTableChartIcon from "@mui/icons-material/PivotTableChart";
import { Box, Button, Dialog, Popover } from "@mui/material";
import usePlaygroundReportEquipmentContext from "./usePlaygroundReportEquipmentContext";
import { useEffect, useState } from "react";
import { EditType } from "./PlaygroundReportEquipmentContext";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import PrevNextButtons from "../PrevNextButtons";
import { useReportApi } from "../../../api/reportApi";
import ImageGalleryWithDelete from "../../common/CustomImageList";
import { styled } from "@mui/material/styles";
import { useQuickFilterWithFiltering } from "../../common/DiacriticSearch";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .super-app-theme--true": {
        backgroundColor: "#ff000033",
        "&:hover": {
            backgroundColor: "#ff000066",
        },
    },
}));

class SelectedRowAndImage {
    constructor(equipmentId, selectedImage) {
        this.equipmentId = equipmentId;
        this.selectedImage = selectedImage;
    }
}

function PlaygroundReportEquipmentList() {
    const {
        images,
        setEditType,
        setEditedId,
        reportEquipments,
        setReportEquipments,
        refreshReportEquipments,
    } = usePlaygroundReportEquipmentContext();

    const { saveEquipmentImage } = useReportApi();
    const [openGallery, setOpenGallery] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    function saveSelectedImage(image) {
        saveEquipmentImage(
            selectedImage.equipmentId,
            image.name,
            (response) => {
                setSelectedImage(null);
                setOpenGallery(false);
                refreshReportEquipments();
            }
        );
    }

    function openImageGallery(id, imageName) {
        setOpenGallery(true);
        setSelectedImage(new SelectedRowAndImage(id, imageName));
    }

    function EquipmentImageCell(props) {
        const { row } = props;
        const [imageName] = useState(row.image);
        const [thumbnail, setThumbnail] = useState(null);

        const [anchorForScaledThumbnail, setAnchorForScaledThumbnail] =
            useState(null);

        const openScaledThumnail = Boolean(anchorForScaledThumbnail);

        useEffect(() => {
            if (imageName && images.length > 0) {
                const image = images.find((image) => image.name === imageName);
                if (image && image) {
                    setThumbnail(image.thumbnail);
                } else {
                    const updatedEquipments = reportEquipments.map(
                        (equipment) => {
                            if (equipment.id === row.id) {
                                equipment.image = undefined;
                            }
                            return equipment;
                        }
                    );

                    setReportEquipments(updatedEquipments);
                }
            }
        }, [images, imageName]);

        return thumbnail ? (
            <div>
                <Box
                    onMouseEnter={(event) =>
                        setAnchorForScaledThumbnail(event.currentTarget)
                    }
                    onMouseLeave={() => setAnchorForScaledThumbnail(null)}
                >
                    <a
                        href="#"
                        onClick={(event) => {
                            openImageGallery(row.id, row.image);
                        }}
                    >
                        <img
                            src={`data:image/jpeg;base64, ${thumbnail}`}
                            height={50}
                        />
                    </a>
                </Box>
                {!openGallery && (
                    <Popover
                        id="mouse-over-popover"
                        sx={{
                            pointerEvents: "none",
                        }}
                        open={openScaledThumnail}
                        anchorEl={anchorForScaledThumbnail}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        onClose={() => setAnchorForScaledThumbnail(null)}
                        disableRestoreFocus
                    >
                        <img
                            height={200}
                            src={`data:image/jpeg;base64, ${thumbnail}`}
                        />
                    </Popover>
                )}
            </div>
        ) : (
            <Button
                variant="text"
                onClick={() => openImageGallery(row.id, row.image)}
            >
                Nincs
            </Button>
        );
    }

    const renderImageCell = (params) => {
        return <EquipmentImageCell {...params} />;
    };

    const navigateToAttributes = (id) => {
        setEditedId(id);
        setEditType(EditType.Attributes);
    };

    const navigateToIssues = (id) => {
        setEditedId(id);
        setEditType(EditType.Issues);
    };

    const columns = [
        {
            field: "manufacturer",
            headerName: "Gyártó",
            minWidth: 70,
            flex: 0.2,
            valueGetter: (params) => params.row.equipment.manufacturer.name,
        },
        {
            field: "name",
            headerName: "Név",
            minWidth: 70,
            flex: 0.2,
            valueGetter: (params) => params.row.equipment.name,
        },
        {
            field: "typeNumber",
            headerName: "Cikkszám",
            minWidth: 130,
            flex: 0.2,
            valueGetter: (params) => params.row.equipment.typeNumber,
        },
        {
            field: "image",
            headerName: "Kép",
            minWidth: 130,
            flex: 0.2,
            renderCell: renderImageCell,
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Adatok",
            flex: 0.1,
            cellClassName: "actions",
            getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={<PivotTableChartIcon />}
                        label="Delete"
                        onClick={() => {
                            navigateToAttributes(params.row.id);
                        }}
                        color="inherit"
                    />,
                ];
            },
        },
        {
            field: "issues",
            type: "actions",
            headerName: "Hibák",
            flex: 0.1,
            cellClassName: "actions",
            getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={<ReportProblemIcon />}
                        label="Delete"
                        onClick={() => {
                            navigateToIssues(params.row.id);
                        }}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    async function gotoPreview() {
        return true;
    }

    // Function to get a field's value for filtering
    const getFieldValue = (row, field) => {
        if (field === "manufacturer") return row.equipment.manufacturer.name;
        if (field === "name") return row.equipment.name;
        if (field === "typeNumber") return row.equipment.typeNumber;
        return row[field]; // Default direct field access
    };

    // Use the custom quick filter hook for multiple fields
    const { filteredRows, quickFilterProps } = useQuickFilterWithFiltering(
        reportEquipments,
        ["manufacturer", "name", "typeNumber"],
        getFieldValue
    );

    return (
        <div style={{ height: 500, width: "100%" }}>
            <StyledDataGrid
                rows={filteredRows}
                getRowId={(row) => row.reportEquipmentId}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[50]}
                disableColumnSelector
                getRowClassName={(params) =>
                    `super-app-theme--${!params.row.approved}`
                }
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps,
                    },
                }}
            />
            <Dialog
                sx={{ maxHeight: "600px" }}
                fullWidth
                maxWidth="md"
                open={openGallery}
                onClose={() => setOpenGallery(false)}
            >
                <ImageGalleryWithDelete
                    images={[...images]}
                    selectedImage={selectedImage?.selectedImage}
                    showDelete={false}
                    onDelete={() => {}}
                    onImageSelect={(image, index) => saveSelectedImage(image)}
                />
            </Dialog>
            <PrevNextButtons
                nextFunction={() => gotoPreview().then((success) => success)}
            />
        </div>
    );
}

export default PlaygroundReportEquipmentList;
