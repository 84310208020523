import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useManufacturerContext from "./useManufacturerContext";
import { useQuickFilterWithFiltering } from "../../common/DiacriticSearch";

const columns = [
    { field: "name", headerName: "Név", minWidth: 70, flex: 0.2 },
    { field: "city", headerName: "Város", minWidth: 130, flex: 0.2 },
    { field: "zip", headerName: "Ir.szám", minWidth: 130, flex: 0.1 },
    { field: "address", headerName: "Cím", minWidth: 130, flex: 0.2 },
    {
        field: "priority",
        headerName: "Kiemelt",
        minWidth: 30,
        flex: 0.1,
        type: "boolean",
    },
];

function ManufacturerTable() {
    const { manufacturers, setSelectedManufacturer } = useManufacturerContext();

    function selectionModelChanged(ids) {
        const id = ids.length !== 0 ? ids[0] : null;
        setSelectedManufacturer(id);
    }

    // Use the custom quick filter hook for multiple fields
    const { filteredRows, quickFilterProps } = useQuickFilterWithFiltering(
        manufacturers,
        ["name", "city", "zip", "address"]
    );

    return (
        <div style={{ height: 500, width: "100%" }}>
            <DataGrid
                rows={filteredRows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps,
                    },
                }}
                onSelectionModelChange={(ids) => selectionModelChanged(ids)}
            />
        </div>
    );
}

export default ManufacturerTable;
