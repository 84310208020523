import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import dayjs from "dayjs";
import useReportListContext from "./useReportListContext";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from "@mui/material";
import { getYearsFromNowTo2023 } from "../../../utils/DateUtils";
import { GridCellWithPopover } from "../../common/GridCellWithPopover";
import { useReportApi } from "../../../api/reportApi";
import { useQuickFilterWithFiltering } from "../../common/DiacriticSearch";

const ReportListPageContent = ({ isReturn }) => {
    const navigate = useNavigate();
    const { reports, reportType, selectedYear, setSelectedYear } =
        useReportListContext();
    const { downloadReport } = useReportApi();

    const generationInfoPopoverContent = (params) => (
        <Box sx={{ p: 1, maxWidth: 400 }}>
            <Typography variant="h7">{params.row.generatedBy}</Typography>
            <Typography sx={{ mt: 2 }} variant="body1">
                {dayjs(params.row.generatedAt).format("YYYY.MM.DD. HH:mm")}
            </Typography>
        </Box>
    );

    const columns = [
        {
            field: "generated",
            headerName: "",
            width: 50,
            renderCell: (params) => {
                return (
                    params.row.generatedAt && (
                        <GridCellWithPopover
                            value={
                                params.row.generatedAt ? (
                                    <GridActionsCellItem
                                        icon={<CheckIcon />}
                                        label="Másolás"
                                        onClick={() =>
                                            downloadReport(params.row.id)
                                        }
                                        color="inherit"
                                    />
                                ) : null
                            }
                            popoverContent={generationInfoPopoverContent(
                                params
                            )}
                        />
                    )
                );
            },
        },
        {
            field: "number",
            headerName: "Sorszám",
            minWidth: 50,
            flex: 0.1,
            valueGetter: (params) => params.row.number,
        },
        {
            field: "date",
            headerName: "Ellenőrzés dátuma",
            minWidth: 70,
            flex: 0.1,
            valueGetter: (params) =>
                dayjs(params.row.date).format("YYYY.MM.DD."),
        },
        {
            field: "shortName",
            headerName: "Rövid megnevezés",
            minWidth: 70,
            flex: 0.2,
        },
        {
            field: "fullName",
            headerName: "Felhasználó",
            minWidth: 70,
            flex: 0.1,
        },
        {
            field: "reportEquipmentCount",
            headerName: "Megf./Össz.",
            minWidth: 70,
            flex: 0.05,
            valueGetter: (params) =>
                `${params.row.approvedReportEquipmentCount} / ${params.row.reportEquipmentCount}`,
        },
        {
            field: "actions",
            type: "actions",
            headerName: "",
            width: 90,
            cellClassName: "actions",
            getActions: ({ id }) => {
                if (isReturn) {
                    return [
                        <GridActionsCellItem
                            icon={<ContentCopyIcon />}
                            label="Másolás"
                            onClick={() => handleCopyClick(id)}
                            color="inherit"
                        />,
                    ];
                } else {
                    return [
                        <GridActionsCellItem
                            icon={
                                <Tooltip title="Szerkesztés">
                                    <EditIcon />
                                </Tooltip>
                            }
                            label="Szerkesztés"
                            onClick={() => handleEditClick(id)}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={
                                <Tooltip title="Generálás">
                                    <PrintIcon />
                                </Tooltip>
                            }
                            label="Generálás"
                            onClick={() => handleGenerateClick(id)}
                            color="inherit"
                        />,
                    ];
                }
            },
        },
    ];

    function handleEditClick(id) {
        navigate(`/report/${reportType}/${id}`);
    }

    function handleCopyClick(id) {
        navigate(`/report/${reportType}/${id}/return`);
    }

    function handleGenerateClick(id) {
        navigate(`/report/${reportType}/${id}/generate`);
    }

    const years = getYearsFromNowTo2023();

    // Use the custom quick filter hook for multiple fields
    const { filteredRows, quickFilterProps } = useQuickFilterWithFiltering(
        reports,
        ["number", "shortName", "fullName"]
    );

    return (
        <Container>
            <FormControl sx={{ mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Év</InputLabel>
                <Select
                    id="year-selection"
                    value={selectedYear}
                    label="Év"
                    onChange={(event) => setSelectedYear(event.target.value)}
                >
                    {years.map((year) => (
                        <MenuItem key={year} value={year}>
                            {year}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <DataGrid
                sx={{ mt: 2 }}
                rows={filteredRows}
                columns={columns}
                autoHeight={true}
                pageSize={Math.min(100, reports.length)}
                rowsPerPageOptions={[Math.min(100, reports.length)]}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps,
                    },
                }}
            />
        </Container>
    );
};

export default ReportListPageContent;
