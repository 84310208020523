import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useAdminUserContext from "./useAdminUserContext";
import { ROLE, ROLES } from "../../../utils/Roles";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import FileUpload from "../../common/FileUpload";
import useAuth from "../../../hooks/useAuth";

function AdminUserEditDialog() {
    const {
        data,
        handleChange,
        showDialog,
        setShowDialog,
        save,
        errors,
        hasError,
        users,
    } = useAdminUserContext();

    const { auth } = useAuth();

    let filteredRoles = [];
    if (ROLE.SUPERADMIN.code === auth?.role) {
        filteredRoles = ROLES;
    } else {
        filteredRoles = ROLES.filter(function (item) {
            const filtered = item.code !== ROLE.SUPERADMIN.code;
            console.log(filtered);
            return filtered;
        });
    }

    const handleClose = (e) => {
        switch (e.target.name) {
            case "save": {
                save();
                break;
            }
            default:
                setShowDialog(false);
                break;
        }
    };

    const title =
        data.id == null ? "Új felhasználó" : "Felhasználó szerkesztés";

    return (
        <Dialog disableEscapeKeyDown open={showDialog} onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic email"
                    label="Email cím"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={data.email}
                    error={errors.email != null}
                    helperText={errors.email}
                    name="email"
                    required
                />
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic email"
                    label="Vezetéknév"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={data.lastName}
                    error={errors.lastName != null}
                    helperText={errors.lastName}
                    name="lastName"
                    required
                />
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic email"
                    label="Keresztnév"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={data.firstName}
                    error={errors.firstName != null}
                    helperText={errors.firstName}
                    name="firstName"
                    required
                />
                <FormControl fullWidth sx={{ width: "100%", my: 1 }}>
                    <InputLabel id="demo-simple-select-label">
                        Szerepkör
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="role"
                        label="Szerepkör"
                        onChange={handleChange}
                        value={data.role}
                        error={errors.role != null}
                        helperText={errors.role}
                    >
                        {filteredRoles.map((value) => (
                            <MenuItem key={value} value={value.code}>
                                {value.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {data.role != ROLE.AUTHORITY.code && (
                    <FormControl fullWidth sx={{ width: "100%", my: 1 }}>
                        <InputLabel id="demo-simple-select-label">
                            Jegyzőkönyv ellenőrző
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="reportVerifier"
                            label="Jegyzőkönyv ellenőrző"
                            onChange={handleChange}
                            value={data.reportVerifier}
                        >
                            {users.map((value) => (
                                <MenuItem key={value.id} value={value.id}>
                                    {`${value.lastName} ${value.firstName}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                <FormControlLabel
                    control={
                        <Checkbox
                            defaultChecked
                            name="active"
                            onChange={handleChange}
                            checked={data.active}
                        />
                    }
                    label="Aktív"
                />
                {data.role != ROLE.AUTHORITY.code && (
                    <FileUpload
                        data={data}
                        name="signature"
                        fileTypes=".png,.jpg"
                        handleChange={handleChange}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button name="cancel" onClick={handleClose}>
                    Mégse
                </Button>
                <Button name="save" disabled={hasError} onClick={handleClose}>
                    Mentés
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AdminUserEditDialog;
