import { Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useEquipmentGroupContext from "./useEquipmentGroupContext";
import { useQuickFilterWithFiltering } from "../../common/DiacriticSearch";

function EquipmentGroupTable() {
    const { equipmentGroups, setSelectedEquipmentGroup, showTableLoading } =
        useEquipmentGroupContext();

    const columns = [
        { field: "name", headerName: "Megnevezés", minWidth: 130, flex: 0.3 },
        {
            field: "description",
            headerName: "Leírás",
            minWidth: 130,
            flex: 0.3,
        },
        {
            field: "count",
            headerName: "Eszközök száma",
            minWidth: 130,
            flex: 0.2,
            valueGetter: (params) => params.row.equipments.length,
        },
    ];

    function selectionModelChanged(ids) {
        const id = ids.length !== 0 ? ids[0] : null;
        setSelectedEquipmentGroup(id);
    }

    // Use the custom quick filter hook for multiple fields
    const { filteredRows, quickFilterProps } = useQuickFilterWithFiltering(
        equipmentGroups,
        ["name", "description"]
    );

    return (
        <div style={{ height: 500, width: "100%" }}>
            <DataGrid
                rows={filteredRows}
                loading={showTableLoading}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps,
                    },
                }}
                onSelectionModelChange={(ids) => selectionModelChanged(ids)}
            />
        </div>
    );
}

export default EquipmentGroupTable;
