import { createContext, useEffect, useState } from "react";
import useReportContext from "./useReportContext";
import { useReportApi } from "../../api/reportApi";
import { IMAGE_URL } from "../../api/Url";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useFullScreenProgressBarContext from "../common/fullscreenprogressbar/useFullScreenProgressBarContext";
import useGeneralErrorContext from "../common/generalerrorsnackbar/useGeneralErrorContext";

const ReportImagesContext = createContext({});

export const ReportImagesProvider = ({ children }) => {
    const axiosPrivate = useAxiosPrivate();
    const { setShowFullScreenProgressBar } = useFullScreenProgressBarContext();
    const { handleGeneralError } = useGeneralErrorContext();

    const { report } = useReportContext();
    const { getReportImages } = useReportApi();
    const [images, setImages] = useState([]);

    function fetchImages(noProgressbar, forceLoad) {
        if (forceLoad || images.length === 0) {
            getReportImages(report.baseData.id, noProgressbar, (response) => {
                setImages(response.data.thumbnails);
            });
        }
    }

    useEffect(() => {
        if (report.baseData.id) {
            fetchImages(false);
        }
    }, [report]);

    async function saveImages(filesToUpload) {
        if (filesToUpload.length === 0) return true;
        setShowFullScreenProgressBar(true);
        return getUploadImageAsync(filesToUpload[0]).then((data) => {
            return Promise.all(
                filesToUpload.slice(1).map((file) => getUploadImageAsync(file))
            )
                .then((data) => {
                    fetchImages(false, true);
                    setShowFullScreenProgressBar(false);
                    return true;
                })
                .catch((errors) => {
                    handleGeneralError(errors[0]);
                    return false;
                });
        });
    }

    function getUploadImageAsync(file) {
        const formData = new FormData();
        formData.append("id", report.baseData.id);
        formData.append(file.name, file);
        return axiosPrivate.post(`${IMAGE_URL}/upload`, formData, {
            headers: { "Content-Type": "image/*" },
            withCredentials: true,
        });
    }

    return (
        <ReportImagesContext.Provider
            value={{
                images,
                setImages,
                fetchImages,
                saveImages,
            }}
        >
            {children}
        </ReportImagesContext.Provider>
    );
};

export default ReportImagesContext;
