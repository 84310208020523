import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useEquipmentTypeContext from "./useEquipmentTypeContext";
import { useQuickFilterWithFiltering } from "../../common/DiacriticSearch";

const columns = [
    { field: "name", headerName: "Név", minWidth: 70, flex: 0.2 },
    {
        field: "description",
        headerName: "Megjegyzés",
        minWidth: 130,
        flex: 0.6,
    },
    {
        field: "categoryName",
        headerName: "Eszköz kategória",
        minWidth: 130,
        flex: 0.2,
        valueGetter: (params) => params.row.category?.name,
    },
];

// Function to get a field's value for filtering
const getFieldValue = (row, field) => {
    if (field === "categoryName") return row.category.name; // Handle valueGetter for operator
    return row[field]; // Default direct field access
};

function EquipmentTypeTable() {
    const { equipmentTypes, setSelectedEquipmentType } =
        useEquipmentTypeContext();

    function selectionModelChanged(ids) {
        const id = ids.length !== 0 ? ids[0] : null;
        setSelectedEquipmentType(id);
    }

    // Use the custom quick filter hook for multiple fields
    const { filteredRows, quickFilterProps } = useQuickFilterWithFiltering(
        equipmentTypes,
        ["name", "description", "categoryName"],
        getFieldValue
    );

    return (
        <div style={{ height: 500, width: "100%" }}>
            <DataGrid
                rows={filteredRows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps,
                    },
                }}
                onSelectionModelChange={(ids) => selectionModelChanged(ids)}
            />
        </div>
    );
}

export default EquipmentTypeTable;
