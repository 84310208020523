import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useCountyAuthorityContext from "./useCountyAuthorityContext";
import { useQuickFilterWithFiltering } from "../../common/DiacriticSearch";

const columns = [
    {
        field: "county",
        headerName: "Vármegye",
        minWidth: 130,
        flex: 0.1,
        valueGetter: (params) => params.row.county.name,
    },
    {
        field: "name",
        headerName: "Név",
        minWidth: 130,
        flex: 0.2,
    },
    {
        field: "address",
        headerName: "Cím",
        minWidth: 130,
        flex: 0.2,
    },
    {
        field: "administrator",
        headerName: "Ügyintéző",
        minWidth: 130,
        flex: 0.2,
    },
    {
        field: "emailAddresses",
        headerName: "Email címek",
        minWidth: 130,
        flex: 0.2,
    },
];

// Function to get a field's value for filtering
const getFieldValue = (row, field) => {
    if (field === "county") return row.county.name; // Handle valueGetter for operator
    return row[field]; // Default direct field access
};

function CountyAuthorityTable() {
    const { authorities, setSelectedAuthority } = useCountyAuthorityContext();

    function selectionModelChanged(ids) {
        const id = ids.length !== 0 ? ids[0] : null;
        setSelectedAuthority(id);
    }

    // Use the custom quick filter hook for multiple fields
    const { filteredRows, quickFilterProps } = useQuickFilterWithFiltering(
        authorities,
        ["county", "name", "address", "administrator", "emailAddresses"], // Specify all searchable fields
        getFieldValue
    );

    return (
        <div style={{ height: 500, width: "100%" }}>
            <DataGrid
                rows={filteredRows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps,
                    },
                }}
                onSelectionModelChange={(ids) => selectionModelChanged(ids)}
            />
        </div>
    );
}

export default CountyAuthorityTable;
