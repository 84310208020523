import TextField from "@mui/material/TextField";
import { Autocomplete, Button, Stack } from "@mui/material";
import { Box } from "@mui/system";
import useReportLocationContext from "./useReportLocationContext";
import OperatorEditDialog from "../../basicdata/operator/OperatorEditDialog";
import useOperatorContext from "../../basicdata/operator/useOperatorContext";
import PrevNextButtons from "../PrevNextButtons";

function ReportLocationPageContent() {
    const {
        cities,
        locations,
        operators,
        data,
        handleChange,
        onNameChange,
        saveLocation,
        copyOperatorData,
        saveReportLocation,
        errors,
        fetchOperators,
        setOperator,
    } = useReportLocationContext();

    const { setShowDialog } = useOperatorContext();
    const uniqueCities = [...new Set(cities.map((city) => city.city))];

    const disabled = data.id !== null;

    return (
        <Box>
            <Autocomplete
                sx={{ width: "100%", my: 1 }}
                id="playground-location-name"
                freeSolo
                name="name"
                inputValue={data.name}
                onChange={onNameChange}
                onBlur={onNameChange}
                onInputChange={(event, newInputValue) => {
                    if (event != null) {
                        event.target.type = "autocomplete";
                        event.target.name = "name";
                        event.target.value = newInputValue;
                        handleChange(event);
                    }
                }}
                options={locations}
                getOptionLabel={(option) =>
                    `${option.name} | ${option.city}, ${option.address}`
                }
                renderInput={(params) => (
                    <TextField {...params} label="Név *" />
                )}
            />
            <Stack direction="row" spacing={2} alignItems="center">
                <Autocomplete
                    sx={{ width: "83%", my: 1 }}
                    id="playground-location-operator"
                    name="operator"
                    value={data.operator}
                    disabled={disabled}
                    onChange={(event, newValue) => {
                        if (event != null) {
                            event.target.type = "autocomplete";
                            event.target.name = "operator";
                            event.target.selectedOption = newValue;
                            handleChange(event);
                        }
                    }}
                    options={operators}
                    getOptionLabel={(option) =>
                        option &&
                        `${option.name} | ${option.city}, ${option.address}`
                    }
                    renderInput={(params) => (
                        <TextField {...params} label="Üzemeltető *" />
                    )}
                />
                <Button
                    variant="outlined"
                    disabled={disabled}
                    name="new"
                    onClick={() => setShowDialog(true)}
                    sx={{ height: 40, my: 2 }}
                >
                    Új üzemeltető
                </Button>
            </Stack>
            {data.operator && (
                <Button onClick={copyOperatorData}>
                    Üzemeltetői adatok másolása
                </Button>
            )}
            <Autocomplete
                sx={{ width: "100%", my: 1, mt: 2 }}
                id="playground-location-zip"
                freeSolo
                name="zip"
                value={data.zip}
                disabled={disabled}
                onInputChange={(event, newInputValue) => {
                    if (event != null) {
                        event.target.name = "zip";
                        event.target.value = newInputValue;
                        handleChange(event);
                    }
                }}
                options={cities.map((city) => city.zip)}
                renderInput={(params) => (
                    <TextField {...params} label="Irányítószám *" />
                )}
            />
            <Autocomplete
                sx={{ width: "100%", my: 1, mt: 2 }}
                id="playground-location-city"
                freeSolo
                name="city"
                value={data.city}
                disabled={disabled}
                onInputChange={(event, newInputValue) => {
                    if (event != null) {
                        event.target.name = "city";
                        handleChange(event);
                    }
                }}
                options={uniqueCities}
                renderInput={(params) => (
                    <TextField {...params} label="Város *" />
                )}
            />
            <TextField
                sx={{ width: "100%", my: 1 }}
                id="playground-location-address"
                label="Cím"
                variant="outlined"
                type="text"
                autoComplete="off"
                onChange={handleChange}
                value={data.address}
                disabled={disabled}
                error={errors.address != null}
                helperText={errors.address}
                name="address"
                required
            />
            <TextField
                sx={{ width: "100%", my: 1 }}
                id="playground-location-contactperson"
                label="Kapcsolattartó"
                variant="outlined"
                type="text"
                autoComplete="off"
                onChange={handleChange}
                value={data.contactPerson}
                error={errors.contactPerson != null}
                helperText={errors.contactPerson}
                name="contactPerson"
            />
            <TextField
                sx={{ width: "100%", my: 1 }}
                id="playground-location-contactperson"
                label="Kapcsolattartó email"
                variant="outlined"
                type="text"
                autoComplete="off"
                onChange={handleChange}
                value={data.contactEmail || ""}
                error={errors.contactEmail != null}
                helperText={errors.contactEmail}
                name="contactEmail"
            />
            <PrevNextButtons
                nextFunction={() =>
                    saveLocation()
                        .then((location) => {
                            return saveReportLocation(location);
                        })
                        .catch((e) => false)
                }
            />
            <OperatorEditDialog
                onClose={(operator) => {
                    fetchOperators().then(() => {
                        setOperator(operator);
                    });
                }}
            />
        </Box>
    );
}

export default ReportLocationPageContent;
