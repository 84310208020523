import {
    DataGrid,
    GridActionsCellItem,
    GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DownloadIcon from "@mui/icons-material/Download";
import EmailIcon from "@mui/icons-material/Email";
import { useReportApi } from "../../api/reportApi";
import PageTitleWithBackButton from "../common/PageTitleWithBackButton";
import { getCurrentYear, getYearsFromNowTo2023 } from "../../utils/DateUtils";
import { ReportType, ReportTypes } from "../../utils/reportType";
import { useQuickFilterWithFiltering } from "../common/DiacriticSearch";

function QuickSearchToolbar({ quickFilterProps }) {
    return (
        <Box sx={{ p: 1 }}>
            <GridToolbarQuickFilter {...quickFilterProps} />
        </Box>
    );
}

const AuthorityReportList = ({}) => {
    const { getAuthorityReportsReportTypeAndYear } = useReportApi();
    const [authorityReports, setAuthorityReports] = useState([]);
    const [selectedYear, setSelectedYear] = useState(getCurrentYear());
    const [selectedReportType, setSelectedReportType] = useState(
        ReportType.PLAYGROUND.urlparam
    );
    const { downloadReport, downloadReports } = useReportApi();
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        fetchAuthorityReports();
    }, [selectedYear, selectedReportType]);

    function fetchAuthorityReports() {
        getAuthorityReportsReportTypeAndYear(
            selectedReportType,
            selectedYear,
            (response) => {
                setAuthorityReports(response.data);
            }
        );
    }

    function handleDownloadClick() {
        downloadReports(selectedRows);
    }

    function sendEmail() {
        const recipient = "iroda@jatszoteror.hu";
        window.location.href = `mailto:${recipient}`;
    }

    const columns = [
        {
            field: "reportId",
            headerName: "Sorszám",
            minWidth: 70,
            flex: 0.1,
        },
        {
            field: "locationName",
            headerName: "Megnevezés",
            minWidth: 70,
            flex: 0.2,
        },
        {
            field: "date",
            headerName: "Dátum",
            minWidth: 70,
            flex: 0.1,
            valueGetter: (params) =>
                dayjs(params.row.date).format("YYYY.MM.DD."),
        },
        {
            field: "location",
            headerName: "Cím",
            minWidth: 70,
            flex: 0.2,
            valueGetter: (params) =>
                params.row.locationZip +
                " " +
                params.row.locationCity +
                " " +
                params.row.locationAddress,
        },
        {
            field: "operatorName",
            headerName: "Üzemeltető",
            minWidth: 70,
            flex: 0.2,
        },
        {
            field: "reportEquipmentCount",
            headerName: "Megf./Össz.",
            minWidth: 70,
            flex: 0.1,
            valueGetter: (params) =>
                `${params.row.approvedReportEquipmentCount} / ${params.row.reportEquipmentCount}`,
        },
        {
            field: "actions",
            type: "actions",
            headerName: "PDF",
            width: 50,
            cellClassName: "actions",
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<PictureAsPdfIcon />}
                        label="Megtekintés"
                        onClick={() => {
                            downloadReport(id);
                        }}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    const years = getYearsFromNowTo2023();

    // Function to get a field's value for filtering
    const getFieldValue = (row, field) => {
        if (field === "location")
            return (
                row.locationZip +
                " " +
                row.locationCity +
                " " +
                row.locationAddress
            );
        return row[field]; // Default direct field access
    };

    // Use the custom quick filter hook for multiple fields
    const { filteredRows, quickFilterProps } = useQuickFilterWithFiltering(
        authorityReports,
        ["reportId", "locationName", "location", "operatorName"],
        getFieldValue
    );

    return (
        <Box sx={{ mt: 2 }}>
            <PageTitleWithBackButton
                title="Jegyzőkönyvek"
                hideBackButton={true}
            />
            <Stack
                sx={{ mt: 2 }}
                direction="row"
                spacing={2}
                alignItems="center"
            >
                <Box sx={{ display: "flex", flexGrow: 1, gap: 2 }}>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">
                            Év
                        </InputLabel>
                        <Select
                            id="year-selection"
                            value={selectedYear}
                            label="Év"
                            onChange={(event) =>
                                setSelectedYear(event.target.value)
                            }
                        >
                            {years.map((year) => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">
                            Jegzõkönyv típus
                        </InputLabel>
                        <Select
                            id="reporttype-selection"
                            value={selectedReportType}
                            label="Jegzõkönyv típus"
                            onChange={(event) =>
                                setSelectedReportType(event.target.value)
                            }
                        >
                            {ReportTypes.map((reportType) => (
                                <MenuItem
                                    key={reportType}
                                    value={reportType.urlparam}
                                >
                                    {reportType.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {selectedRows.length > 0 && (
                        <Button
                            sx={{ mx: 1 }}
                            variant="contained"
                            name="edit"
                            startIcon={<DownloadIcon />}
                            onClick={handleDownloadClick}
                        >
                            Letöltés
                        </Button>
                    )}
                </Box>
                <IconButton onClick={() => sendEmail()}>
                    <Tooltip title="Email küldés: iroda@jatszoteror.hu">
                        <EmailIcon />
                    </Tooltip>
                </IconButton>
            </Stack>
            <DataGrid
                sx={{ mt: 2 }}
                rows={filteredRows}
                columns={columns}
                autoHeight={true}
                checkboxSelection
                onSelectionModelChange={setSelectedRows}
                pageSize={Math.min(100, authorityReports.length)}
                rowsPerPageOptions={[Math.min(100, authorityReports.length)]}
                components={{ Toolbar: QuickSearchToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: quickFilterProps,
                    },
                }}
            />
        </Box>
    );
};

export default AuthorityReportList;
