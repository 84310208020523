import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import usePlaygroundReportEquipmentsByManufacturerContext from "../equipmentsByManufacturer/usePlaygroundReportEquipmentsContext";
import useManufacturerContext from "../../../basicdata/manufacturer/useManufacturerContext";
import useEquipmentContext from "../../../basicdata/equipment/useEquipmentContext";
import { useQuickFilterWithFiltering } from "../../../common/DiacriticSearch";
import EquipmentEditDialog from "../../../basicdata/equipment/EquipmentEditDialog";
import ManufacturerEditDialog from "../../../basicdata/manufacturer/ManufacturerEditDialog";
import PrevNextButtons from "../../PrevNextButtons";
import useReportContext from "../../useReportContext";

const countMap = new Map();

function QuickSearchToolbar({ quickFilterProps }) {
    return (
        <Box sx={{ p: 1 }}>
            <GridToolbarQuickFilter {...quickFilterProps} />
        </Box>
    );
}

function EntertainmentReportEquipment() {
    const {
        equipments,
        manufacturers,
        fetchManufacturers,
        showTableLoading,
        selectedManufacturer,
        setSelectedManufacturer,
        fetchEquipmentsByManufacturer,
    } = usePlaygroundReportEquipmentsByManufacturerContext();
    const { setShowNewManufacturerDialog } = useManufacturerContext();
    const { reportConfig } = useReportContext();
    const { selectEquipment, setShowNewEquipmentDialog } =
        useEquipmentContext();

    const columns = [
        { field: "name", headerName: "Név", minWidth: 70, flex: 0.2 },
        {
            field: "typeNumber",
            headerName: "Cikkszám",
            minWidth: 130,
            flex: 0.2,
        },
        {
            field: "certificateNumber",
            headerName: "Tanúsítvány szám",
            minWidth: 130,
            flex: 0.2,
        },
    ];

    const [selectedRow, setSelectedRow] = useState(null);

    const handleRowClick = (params) => {
        setSelectedRow(params.id);
    };

    const handleAdd = () => {
        const selected = equipments.find((item) => item.id === selectedRow);
        if (selected) {
            selectEquipment(selected.id);
        }
    };

    const handleBack = () => {
        setSelectedRow(null);
    };

    const { filteredRows, quickFilterProps } = useQuickFilterWithFiltering(
        equipments,
        ["name", "typeNumber", "certificateNumber"]
    );

    return (
        <div style={{ height: 500, width: "100%" }}>
            <Stack direction="row" spacing={2} alignItems="center">
                <Autocomplete
                    sx={{ width: "87%", my: 2 }}
                    id="free-solo-demo"
                    name="manufacturer"
                    value={selectedManufacturer}
                    onChange={(event, newInputValue) => {
                        if (event != null) {
                            event.target.type = "autocomplete";
                            event.target.name = "manufacturer";
                            event.target.selectedOption = newInputValue;
                            setSelectedManufacturer(newInputValue);
                        }
                    }}
                    options={manufacturers}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField {...params} label="Gyártó" />
                    )}
                />
                <Button
                    variant="outlined"
                    name="new"
                    onClick={() => setShowNewManufacturerDialog(true)}
                >
                    Új gyártó
                </Button>
            </Stack>
            <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
                <Button
                    sx={{ mb: 2 }}
                    disabled={!selectedManufacturer}
                    variant="contained"
                    name="new"
                    onClick={() => setShowNewEquipmentDialog(true)}
                >
                    Új eszköz
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button
                    sx={{ mb: 2 }}
                    variant="contained"
                    onClick={handleAdd}
                    disabled={!selectedRow}
                >
                    Hozzáadás
                </Button>
            </Box>
            <DataGrid
                rows={filteredRows}
                loading={showTableLoading}
                columns={columns}
                pageSize={Math.min(100, equipments.length)}
                rowsPerPageOptions={[Math.min(100, equipments.length)]}
                disableColumnSelector
                onRowClick={handleRowClick}
                components={{ Toolbar: QuickSearchToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: quickFilterProps,
                    },
                }}
            />
            <PrevNextButtons nextFunction={() => true} />
            <ManufacturerEditDialog
                onClose={(manufacturer) => {
                    fetchManufacturers().then(() => {
                        setSelectedManufacturer(manufacturer);
                    });
                }}
            />
            <EquipmentEditDialog
                manufacturer={selectedManufacturer}
                equipmentCategory={reportConfig.equipmentCategory}
                onClose={(equipment) => {
                    fetchEquipmentsByManufacturer(selectedManufacturer);
                }}
            />
        </div>
    );
}

export default EntertainmentReportEquipment;
