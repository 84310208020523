import { Box, Button, Card, TextField } from "@mui/material";
import { useRef, useEffect, useReducer, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import md5 from "md5";

import {
    loginReducer,
    LOGIN_ACTION_TYPES,
    LOGIN_INITIAL_STATE,
} from "./loginReducer";
import { Stack } from "@mui/system";
import useGeneralErrorContext from "../common/generalerrorsnackbar/useGeneralErrorContext";
import useFullScreenProgressBarContext from "../common/fullscreenprogressbar/useFullScreenProgressBarContext";
import { LOGIN_URL } from "../../api/Url";

const Login = () => {
    const { setShowFullScreenProgressBar } = useFullScreenProgressBarContext();
    const { handleGeneralError } = useGeneralErrorContext();
    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const queryParams = location.state?.queryParams;

    const [state, dispatch] = useReducer(loginReducer, LOGIN_INITIAL_STATE);
    const [errMsg, setErrMsg] = useState("");
    const emailRef = useRef();
    const errRef = useRef();

    useEffect(() => {
        const node = emailRef.current;
        node?.focus();
    }, []);

    useEffect(() => {
        setErrMsg("");
    }, [state.email, state.passwordpwd]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowFullScreenProgressBar(true);

        const md5Password = md5(state.password);
        try {
            const response = await axios.post(
                LOGIN_URL,
                JSON.stringify({
                    email: state.email,
                    password: md5Password,
                }),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );
            const accessToken = response?.data?.accessToken;
            const role = response?.data?.role;
            const email = state.email;
            const password = md5Password;
            localStorage.setItem("lastEmail", email);
            setAuth({ email, password, role, accessToken });
            dispatch({ type: LOGIN_ACTION_TYPES.CLEAR_INPUT_VALUES });

            const queryString = new URLSearchParams(queryParams).toString();
            const navigateTo = queryString ? `${from}?${queryString}` : from;
            navigate(navigateTo, { replace: true });
        } catch (error) {
            if (error.response?.status === 400) {
                setErrMsg("Hibás email cím vagy jelszó");
            } else {
                handleGeneralError(error);
            }
        } finally {
            setShowFullScreenProgressBar(false);
        }
    };

    const setInputValue = (e) => {
        dispatch({
            type: LOGIN_ACTION_TYPES.SET_INPUT_VALUE,
            payload: { name: e.target.name, value: e.target.value },
        });
    };

    const onForgotPasswordClick = () => {
        navigate("/forgotpassword");
    };

    return (
        <section>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <Card component="span" sx={{ minWidth: 275, p: 2 }}>
                    <p
                        style={{ color: "red" }}
                        ref={errRef}
                        className={errMsg ? "errmsg" : "offscreen"}
                    >
                        {errMsg}
                    </p>
                    <h1>Bejelentkezés</h1>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            sx={{ width: "100%", my: 1 }}
                            id="outlined-basic email"
                            label="Email cím"
                            variant="outlined"
                            type="text"
                            ref={emailRef}
                            autoComplete="off"
                            onChange={setInputValue}
                            value={state.email}
                            name="email"
                            required
                        />
                        <br />
                        <TextField
                            sx={{ width: "100%", my: 1 }}
                            id="outlined-basic password"
                            label="Jelszó"
                            variant="outlined"
                            type="password"
                            onChange={setInputValue}
                            value={state.password}
                            name="password"
                            required
                        />
                        <br />
                        <Stack direction="row" spacing={2} m={2}>
                            <Button
                                variant="text"
                                onClick={onForgotPasswordClick}
                            >
                                Elfelejtett jelszó
                            </Button>
                            <Button variant="contained" type="submit">
                                Belépés
                            </Button>
                        </Stack>
                    </form>
                </Card>
            </Box>
        </section>
    );
};

export default Login;
