import { createContext, useState, useEffect } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { REPORT_URL } from "../../api/Url";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useFullScreenProgressBarContext from "../common/fullscreenprogressbar/useFullScreenProgressBarContext";
import useGeneralErrorContext from "../common/generalerrorsnackbar/useGeneralErrorContext";
import {
    getReportTypeByCode,
    getReportTypeByUrlParam,
} from "../../utils/reportType";
import { useReportApi } from "../../api/reportApi";

const emptyBaseData = {
    id: null,
    shortName: "",
    number: "",
    date: new Date(),
    year: null,
    reportType: "",
    priorityManufacturer: null,
    trelloCardId: null,
};

export const emptyLocation = {
    id: null,
    name: "",
    city: "",
    zip: "",
    address: "",
    operator: null,
    contactPerson: "",
};

const emptyReport = {
    baseData: emptyBaseData,
    location: emptyLocation,
    coverImage: null,
    equipments: [],
};

const ReportContext = createContext({});

export const ReportProvider = ({ children }) => {
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const isReturn = params.option === "return";
    const isGenerate = params.option === "generate";
    const reportId = params.reportId;

    const trelloCardId = searchParams.get("trellocardid");
    emptyBaseData.trelloCardId = trelloCardId;

    const { getReportConfig } = useReportApi();
    const { setShowFullScreenProgressBar } = useFullScreenProgressBarContext();
    const { handleGeneralError } = useGeneralErrorContext();
    const axiosPrivate = useAxiosPrivate();
    const [isNextEnabled, setNextEnabled] = useState(false);

    let startStep;
    if (isGenerate) {
        startStep = 5;
    } else {
        startStep = 0;
    }
    const [activeStep, setActiveStep] = useState(startStep);

    const [report, setReport] = useState(emptyReport);
    const [reportConfig, setReportConfig] = useState();
    const location = useLocation();
    const reportTypeFromUrl = location.pathname.split("/")[2];

    useEffect(() => {
        let reportType = getReportTypeByUrlParam(
            reportTypeFromUrl.toLowerCase()
        );
        fetchReportConfig(reportType);
        if (reportId) {
            fetchReport();
        } else {
            setReport((prevData) => ({
                ...prevData,
                baseData: {
                    ...prevData.baseData,
                    reportType: reportType,
                },
            }));
        }
    }, []);

    useEffect(() => {
        console.log(`isNextEnabled: ${isNextEnabled}`);
    }, [isNextEnabled, setNextEnabled]);

    function fetchReportConfig(reportType) {
        getReportConfig(reportType, (response) => {
            setReportConfig(response.data);
        });
    }

    const fetchReport = async (e) => {
        setShowFullScreenProgressBar(true);
        try {
            const response = await axiosPrivate.get(
                `${REPORT_URL}/${reportId}`,
                {
                    withCredentials: true,
                }
            );
            const baseData = response.data;
            const location = baseData.location;
            delete baseData.location;
            if (isReturn) {
                baseData.year = null;
            }
            setReport((prevData) => ({
                ...prevData,
                baseData: {
                    ...baseData,
                    reportType: getReportTypeByCode(baseData.reportType),
                },
                location: location,
            }));
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    };

    function updateReport(name, value) {
        let updatedValue;
        if (name === "baseData") {
            updatedValue = {
                ...value,
                reportType: getReportTypeByCode(value.reportType),
            };
        } else {
            updatedValue = value;
        }
        setReport((prevData) => ({
            ...prevData,
            [name]: updatedValue,
        }));
    }

    return (
        <ReportContext.Provider
            value={{
                report,
                setReport,
                reportConfig,
                updateReport,
                isNextEnabled,
                setNextEnabled,
                activeStep,
                setActiveStep,
            }}
        >
            {children}
        </ReportContext.Provider>
    );
};

export default ReportContext;
