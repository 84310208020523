import { createTheme, ThemeProvider } from "@mui/material";
import { createContext, useEffect, useMemo, useState } from "react";
import { huHU } from "@mui/x-data-grid";

const ColorModeContext = createContext({ toggleColorMode: () => {} });

export const ToggleColorMode = ({ children }) => {
    const [mode, setMode] = useState(
        localStorage.getItem("colorMode") ?? "light"
    );

    useEffect(() => {
        localStorage.setItem("colorMode", mode);
    }, [mode]);

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) =>
                    prevMode === "light" ? "dark" : "light"
                );
            },
        }),
        []
    );

    const theme = useMemo(
        () =>
            createTheme(
                {
                    palette: {
                        mode,
                    },
                },
                huHU
            ),
        [mode]
    );

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ColorModeContext.Provider>
    );
};

export default ColorModeContext;
